<template>
    <div class="container">
        <h1><slot></slot></h1>
    </div>
</template>

<style lang="scss" scoped>
    h1 {
        font-size: 36px;
        font-weight: 500;
        line-height: 1.2;
        padding: 0;
        margin: 15px 0 17px 0;
    }
</style>