export default class FilesService {

	// проверка файла перед импортом
	checkObjectsFile(formData) {
        // // var token = this.getCookie("pd_token");
		// const formData = new FormData();
		// formData.append('action', 'getAllUsers');
		// formData.append('token', token);
		// // formData.append('c_user_id', localStorage.starta_id);
		// // formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/upload_materials.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// импорт материалов из массива
	importObjects(formData) {
        // // var token = this.getCookie("pd_token");
		// const formData = new FormData();
		// formData.append('action', 'getAllUsers');
		// formData.append('token', token);
		// // formData.append('c_user_id', localStorage.starta_id);
		// // formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/upload_materials.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
                // console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// экспорт материалов в xlsx
	exportObjects() {
        // // var token = this.getCookie("pd_token");
		const formData = new FormData();
		formData.append('action', 'exportObjects');
		// formData.append('token', token);
		// // formData.append('c_user_id', localStorage.starta_id);
		// // formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/upload_materials.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
                // console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// экспорт сметы в xlsx
	getXLSX(id) {
        // // var token = this.getCookie("pd_token");
		const formData = new FormData();
		formData.append('action', 'getXLSX');
		formData.append('id', id);
		// formData.append('token', token);
		// // formData.append('c_user_id', localStorage.starta_id);
		// // formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/upload_materials.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
                // console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}
}