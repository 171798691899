<template>
  <header class="main-header">
    <div class="container">
      <div class="main-header_logo">
        <router-link to="/">
          <img src="@/assets/img/logo.svg" alt="ПапаDom Estimation" />
        </router-link>
      </div>
      <div class="main-header_menu">
        <Menubar :model="items">

        </Menubar>
      </div>
      <div class="main-header_controls">
        <Menubar :model="controlItems" />
      </div>
    </div>
  </header>
  <!-- <crumbs v-if="!crumbsDisabled" :items="crumbsItems"></crumbs> -->
</template>

<script>
// import AuthService from '../services/AuthService';
export default {
  props: ["userData", "crumbsDisabled"],
  emits: ["logout"],
  // AuthService: null,
  // created() {
  //   this.AuthService = new AuthService();
  // },
  data() {
    return {
      items: [
        {
          label: "Дашборд",
          icon: "pi pi-fw pi-home",
          to: "/",
          visible: true
        },
        {
          label: "Сметы",
          icon: "pi pi-fw pi-table",
          to: "/estimates",
          visible: this.checkVisible(["manager", "estimator", "templater"])
        },
        {
          label: "Управление",
          icon: "pi pi-fw pi-cog",
          items: [
            {
              label: "Материалы и работы",
              icon: "pi pi-fw pi-angle-right",
              to: "/objects",
              visible: this.checkVisible(["director", "supplier"])
            },
            {
              label: "Доп.расходы",
              icon: "pi pi-fw pi-angle-right",
              to: "/expenses",
              visible: this.checkVisible(["director", "supplier"])
            },
            {
              label: "Шаблоны смет",
              icon: "pi pi-fw pi-angle-right",
              to: "/templates",
              visible: this.checkVisible(["director", "templater"]),
              items: [
                {
                  label: "Разделы этапов",
                  icon: "pi pi-fw pi-angle-right",
                  to: "/workflows",
                  visible: this.checkVisible(["director", "supplier", "templater"])
                }
              ]
            },
            {
              label: "Пользователи",
              icon: "pi pi-fw pi-angle-right",
              to: "/users",
              visible: this.checkVisible(["director"])
            },
          ],
          visible: this.checkVisible(["director", "supplier", "templater"])
        },
        {
          label: "Помощь",
          icon: "pi pi-fw pi-question-circle",
          to: "/help",
          visible: true
        },
      ],
      controlItems: [
        {
          label: this.userData.firstname + ' ' + this.userData.lastname,
          icon: "pi pi-fw pi-user",
          disabled: !this.checkVisible(["director", "support"]),
          to: "/profile",
        },
        {
          label: "Выйти",
          icon: "pi pi-fw pi-sign-out",
          command: () => {this.logout()}
        },
      ],
    };
  },
  methods: {
    logout() {
      // this.AuthService.logout(this);
      this.$emit('logout');
    },
    checkVisible(rolesArray) {
      if (rolesArray.includes(this.userData.role) || this.userData.permissions == 'admin') {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main-header {
  height: 78px;
  background: #ffffff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05);
  position: sticky;
  left: 0;
  right: 0;
  top: 0%;
  z-index: 1001;
  .container {
    display: flex;
    align-items: center;
    height: 100%;
  }
  &_logo {
    flex: 0 0 248px;
    width: 248px;
    max-width: 248px;
  }
  .p-menubar {
    border: none;
    background: transparent;
  }
  nav {
    ul {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        padding: 0 22px;
      }
    }
  }
  &_menu {
    flex: 100%;
  }
  &_controls {
    flex: 0 0 auto;
    .p-menubar {
      justify-content: flex-end;
    }
  }
}
</style>