<template>
  <Toast />
  <!-- <ConfirmPopup /> -->
  <ConfirmDialog></ConfirmDialog>
  <admin-mode-plate v-if="showAdminModePlate"></admin-mode-plate>
  <page-header
    @logout="logout()"
    :userData="userData"
    v-if="userData"
    :crumbsDisabled="$route.path === '/' ? true : false"
  ></page-header>
  <router-view :userData="userData" />
  <!-- <div class="lock-overlay" v-else><h1>Доступ запрёщен</h1></div> -->
</template>

<script>
import AuthService from "./services/AuthService";

export default {
  data() {
    return {
      userData: null,
      token: null,
      routePermissions: [],
      routeRoles: [],
      showAdminModePlate: false,
      lockOverlay: false,
    };
  },
  AuthService: null,
  created() {
    this.AuthService = new AuthService();
  },
  mounted() {
    this.AuthService.checkAuth(this);
    // let checkUserData = setInterval(() => {
    //   if (this.userData != null) {

    //     clearInterval(checkUserData);
    //   }
    // }, 200);
  },
  methods: {
    checkPermissions() {
      const that = this;
      // проверка доступа к разделу
      if (this.userData.permissions != "admin") {
        // если юзер не админ, то проверяем дальше
        if (this.routePermissions.length > 0) {
          // если для страницы указаны ограничения по группе, то проверяем дальше
          if (this.routePermissions.includes(this.userData.permissions)) {
            // смотрим вхождения прав юзера и ограничений страницы. Если ограничения пройдены, то проверяем доступ по ролям
            if (this.routeRoles.length > 0) {
              // если для страницы указаны ограничения по ролям, то проверяем дальше
              if (this.routeRoles.includes(this.userData.role)) {
                console.log("Доступ разрешен");
              } else {
                that.$toast.add({
                  severity: "error",
                  summary: "Доступ ограничен",
                  detail: "Недостаточно прав",
                  life: 4000,
                });
                this.$router.push("/");
              }
            }
          } else {
            that.$toast.add({
              severity: "error",
              summary: "Доступ ограничен",
              detail: "Недостаточно прав",
              life: 4000,
            });
            this.$router.push("/");
          }
        }
      }
    },
    logout() {
      this.userData = null;
      this.showAdminModePlate = false;
      this.AuthService.logout(this);
    },
  },
  watch: {
    $route(to) {
      this.routePermissions = to.meta.permissions;
      this.routeRoles = to.meta.roles;
      this.AuthService.checkAuth(this);

      let checkUserData = setInterval(() => {
        if (this.userData != null) {
          this.checkPermissions();
          if (this.userData.permissions == "admin") {
            this.showAdminModePlate = true;
          }
          clearInterval(checkUserData);
        }
      }, 200);

      document.title = to.meta.title + " | ПапаDom" || "ПапаDom";

      var metaRobots = document.createElement("meta");
      metaRobots.name = "robots";
      metaRobots.content = "noindex, nofollow";
      document.getElementsByTagName("head")[0].appendChild(metaRobots);
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

* {
  // color: $main_color;
  font-family: "Ubuntu", sans-serif;
}

// p, ul {
//   // color: $main_color;
// }
.p-toast {
  z-index: 20000 !important;
}
p {
  margin: 0 0 10px 0;
}

a {
  color: $main_color;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  background: #efefef;
}
.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  // border: 1px red solid;
  padding: 0 15px;
}

.card {
  background: #ffffff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05);
  padding: 13px 23px;
}

.form_field {
  margin-bottom: 20px;
  label {
    > span {
      font-size: 18px;
      margin-bottom: 5px;
      display: block;
    }
    input {
      height: 38px;
      line-height: 38px;
      border: 1px $gray_color solid;
      font-size: 16px;
      background: #f9f9f9;
      border-radius: 0;
    }
    .p-dropdown {
      padding: 9px 0;
      border-radius: 0;
      background: #f9f9f9;
    }
    .p-inputtext {
      padding: 0 10px;
      margin: 0;
      width: 100%;
    }
    .p-button {
      height: 49px;
      background: $secondary_color;
      border-color: $secondary_color;
      font-size: 18px;
      .p-button-label {
        color: white;
      }
    }
    .p-inputswitch {
      .p-inputswitch-slider {
        border-radius: 0;
        // height: 37px;
        // border: 1px $info_color solid;
        // background: #c4c4c4;
        // width: 70px;
        &:before {
          border-radius: 0;
          // background: $info_color;
          // width: 28px;
          // height: 28px;
          // top: 50%;
          // transform: translateY(-50%);
          // margin: 0;
        }
      }
      &-checked {
        .p-inputswitch-slider {
          background: $secondary_color;
          //     &:before {
          //       transform: translateY(-50%) translateX(30px);
          //     }
        }
      }
    }
  }
  &_sizes {
    .inputs_wrapper {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      // justify-content: space-between;
      > span {
        flex: 0 0 33%;
        width: 33%;
        max-width: 45px;
        text-align: center;
        input {
          width: 100%;
          text-align: center;
        }
        &:nth-of-type(2) {
          max-width: 25px;
        }
      }
    }
  }
}

.p-button {
  background: $secondary_color;
  border-color: $secondary_color;
  color: white;
  border-radius: 0;
  // &-label {
  //   // color: white;
  // }
  // .pi {
  //   // color: white;
  // }
}

.p-tieredmenu {
  width: 100% !important;
}
.p-tieredmenu-overlay {
  max-width: 400px !important;
}

.lock-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #223148;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: white;
  }
}

// .p-dialog-mask {
//   z-index: 10010 !important;
// }


.tmp_estimate_col {
    &_type {
        text-align: center !important; 
        flex: 0 0 40px;
        width: 40px;
        max-width: 40px;
    }
    &_name {
        flex: auto;
    }
    &_article {
        flex: 0 0 10%;
        width: 10%;
        max-width: 10%;
    }
    &_vendor {
        flex: 0 0 13%;
        width: 13%;
        max-width: 13%;
    }
    &_vendor_article {
        flex: 0 0 10%;
        width: 10%;
        max-width: 10%;
    }
    &_unit {
        flex: 0 0 7%;
        width: 7%;
        max-width: 7%;
    }
    &_markup {
        flex: 0 0 7%;
        width: 7%;
        max-width: 7%;
    }
    &_price {
        flex: 0 0 11%;
        width: 11%;
        max-width: 11%;
    }
    &_count {
        flex: 0 0 8%;
        width: 8%;
        max-width: 8%;
    }
    &_controls {
        text-align: center !important; 
        flex: 0 0 50px;
        width: 50px;
        max-width: 50px;
    }
}

.p-datatable .p-datatable-header {
    background: #ffffcd !important;
}

</style>