export default class EstimateService {

	getEstimateData(id) {
		const formData = new FormData();
		formData.append('action', 'getEstimateData');
		// formData.append('token', localStorage.pd_token);
		// formData.append('c_user_id', localStorage.starta_id);
		formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	getEstimateSteps(typeID) {
		const formData = new FormData();
		formData.append('action', 'getEstimateSteps');
		// formData.append('token', localStorage.pd_token);
		// formData.append('c_user_id', localStorage.starta_id);
		formData.append('type_id', typeID);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	getEstimateWorkflows(stepID) {
		const formData = new FormData();
		formData.append('action', 'getEstimateWorkflows');
		// formData.append('token', localStorage.pd_token);
		// formData.append('c_user_id', localStorage.starta_id);
		formData.append('step_id', stepID);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// получение данных об этапе по его ID, а также данных о связанных работах
	getStepData(id) {
		const formData = new FormData();
		formData.append('action', 'getStepData');
		// formData.append('token', localStorage.pd_token);
		// formData.append('c_user_id', localStorage.starta_id);
		formData.append('step_id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// получение данных о материалах по ID работ, а также данных о связанных материалах
	getWorkflowMaterials(id) {
		const formData = new FormData();
		formData.append('action', 'getWorkflowMaterials');
		// formData.append('token', localStorage.pd_token);
		// formData.append('c_user_id', localStorage.starta_id);
		formData.append('workflows_id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// получение данных о работах по ID работ
	getWorkflowWorks(id) {
		const formData = new FormData();
		formData.append('action', 'getWorkflowWorks');
		// formData.append('token', localStorage.pd_token);
		// formData.append('c_user_id', localStorage.starta_id);
		formData.append('workflows_id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// получение данных о доп.расходах по ID работ
	getWorkflowExpenses(id) {
		const formData = new FormData();
		formData.append('action', 'getWorkflowExpenses');
		// formData.append('token', localStorage.pd_token);
		// formData.append('c_user_id', localStorage.starta_id);
		formData.append('workflows_id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// получение всех шаблонов смет
	getAllTypes() {
		const formData = new FormData();
		formData.append('action', 'getAllTypes');
		// formData.append('token', localStorage.pd_token);
		// formData.append('c_user_id', localStorage.starta_id);
		// formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// получение всех разделов этапов
	getAllWorkflows() {
		const formData = new FormData();
		formData.append('action', 'getAllWorkflows');
		// formData.append('token', localStorage.pd_token);
		// formData.append('c_user_id', localStorage.starta_id);
		// formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// получение всех материалов из базы
	getAllObjects(params) {
		
		const formData = new FormData();
		//const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
		// console.log(params);
		formData.append('action', 'getAllObjects');
		formData.append('params', params);
		// formData.append('token', localStorage.pd_token);
		// formData.append('c_user_id', localStorage.starta_id);
		// formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// получение списк связанных материалов по ID
	getMaterialRelations(id) {
		const formData = new FormData();
		formData.append('action', 'getMaterialRelations');
		// formData.append('token', localStorage.pd_token);
		// formData.append('c_user_id', localStorage.starta_id);
		formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})		
	}

	// удаление связи материалов по ID
	removeRelation(related_product, product_id) {
		const formData = new FormData();

		formData.append('action', 'removeRelation');
		formData.append('related_product', related_product);
		formData.append('product_id', product_id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// получение всех доп.расходов из базы
	getAllExpenses() {
		const formData = new FormData();
		formData.append('action', 'getAllExpenses');
		// formData.append('token', localStorage.pd_token);
		// formData.append('c_user_id', localStorage.starta_id);
		// formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// получение всех смет из базы с учётом прав
	getAllEstimates(permissons, role, user_id) {
		const formData = new FormData();
		formData.append('action', 'getAllEstimates');
		formData.append('permissions', permissons);
		formData.append('role', role);
		formData.append('user_id', user_id);
		// formData.append('token', localStorage.pd_token);
		// formData.append('c_user_id', localStorage.starta_id);
		// formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}
		
	// сохранение сметы по ID
	saveEstimate(formData) {
		
		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// сохранение шаблона по ID
	saveTemplate(formData) {
		
		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}	

	// удаление сметы по ID
	removeEstimate(id) {
		const formData = new FormData();

		formData.append('action', 'removeEstimate');
		formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// удаление шаблона по ID
	removeTemplate(id) {
		const formData = new FormData();

		formData.append('action', 'removeTemplate');
		formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}	

	// создание сметы
	createEstimate(formData) {

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
			method: "POST",
			body: formData
		})
		.then((response) => response.json())
		.then(function (data) {
			// console.log(data);
			return Promise.resolve(data);
		})
		.catch(function (err) {
			var data = {
				status: false,
				message: err
			}
			return Promise.resolve(data);
		})
	}

	// обновление материала
	updateMaterial(formData) {

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
			method: "POST",
			body: formData
		})
		.then((response) => response.json())
		.then(function (data) {
			// console.log(data);
			return Promise.resolve(data);
		})
		.catch(function (err) {
			var data = {
				status: false,
				message: err
			}
			return Promise.resolve(data);
		})
	}

	// обновление работ
	updateWorks(formData) {

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
			method: "POST",
			body: formData
		})
		.then((response) => response.json())
		.then(function (data) {
			// console.log(data);
			return Promise.resolve(data);
		})
		.catch(function (err) {
			var data = {
				status: false,
				message: err
			}
			return Promise.resolve(data);
		})
	}

	// обновление расхода
	updateExpense(formData) {

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
			method: "POST",
			body: formData
		})
		.then((response) => response.json())
		.then(function (data) {
			// console.log(data);
			return Promise.resolve(data);
		})
		.catch(function (err) {
			var data = {
				status: false,
				message: err
			}
			return Promise.resolve(data);
		})
	}

	// создание материала
	createMaterial(formData) {

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
			method: "POST",
			body: formData
		})
		.then((response) => response.json())
		.then(function (data) {
			// console.log(data);
			return Promise.resolve(data);
		})
		.catch(function (err) {
			var data = {
				status: false,
				message: err
			}
			return Promise.resolve(data);
		})
	}

	// создание материала
	createWorks(formData) {

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
			method: "POST",
			body: formData
		})
		.then((response) => response.json())
		.then(function (data) {
			// console.log(data);
			return Promise.resolve(data);
		})
		.catch(function (err) {
			var data = {
				status: false,
				message: err
			}
			return Promise.resolve(data);
		})
	}

	// создание расхода
	createExpense(formData) {

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
			method: "POST",
			body: formData
		})
		.then((response) => response.json())
		.then(function (data) {
			// console.log(data);
			return Promise.resolve(data);
		})
		.catch(function (err) {
			var data = {
				status: false,
				message: err
			}
			return Promise.resolve(data);
		})
	}
	
	// создание шаблона
	createTemplate(formData) {

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
			method: "POST",
			body: formData
		})
		.then((response) => response.json())
		.then(function (data) {
			// console.log(data);
			return Promise.resolve(data);
		})
		.catch(function (err) {
			var data = {
				status: false,
				message: err
			}
			return Promise.resolve(data);
		})
	}	

	// удаление материала по ID
	removeObject(id) {
		const formData = new FormData();

		formData.append('action', 'removeObject');
		formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// удаление расхода по ID
	removeExpense(id) {
		const formData = new FormData();

		formData.append('action', 'removeExpense');
		formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}

	// создание нового этапа
	createStep(formData) {

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
			method: "POST",
			body: formData
		})
		.then((response) => response.json())
		.then(function (data) {
			return Promise.resolve(data);
		})
		.catch(function (err) {
			var data = {
				status: false,
				message: err
			}
			return Promise.resolve(data);
		})
	}

	// изменение названия этапа
	changeStep(formData) {

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
			method: "POST",
			body: formData
		})
		.then((response) => response.json())
		.then(function (data) {
			return Promise.resolve(data);
		})
		.catch(function (err) {
			var data = {
				status: false,
				message: err
			}
			return Promise.resolve(data);
		})
	}

	// удаление этапа и связанных с ним работ
	deleteStep(id) {
		const formData = new FormData();

		formData.append('action', 'deleteStep');
		formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
			method: "POST",
			body: formData
		})
		.then((response) => response.json())
		.then(function (data) {
			return Promise.resolve(data);
		})
		.catch(function (err) {
			var data = {
				status: false,
				message: err
			}
			return Promise.resolve(data);
		})
	}

	// создание новых работ
	createWorkflow(formData) {

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
			method: "POST",
			body: formData
		})
		.then((response) => response.json())
		.then(function (data) {
			return Promise.resolve(data);
		})
		.catch(function (err) {
			var data = {
				status: false,
				message: err
			}
			return Promise.resolve(data);
		})
	}

	// изменение названия раздела
	changeWorkflow(formData) {

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
			method: "POST",
			body: formData
		})
		.then((response) => response.json())
		.then(function (data) {
			return Promise.resolve(data);
		})
		.catch(function (err) {
			var data = {
				status: false,
				message: err
			}
			return Promise.resolve(data);
		})
	}

	// удаление работ
	deleteWorkflow(id) {
		const formData = new FormData();

		formData.append('action', 'deleteWorkflow');
		formData.append('id', id);
		
		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
			method: "POST",
			body: formData
		})
		.then((response) => response.json())
		.then(function (data) {
			return Promise.resolve(data);
		})
		.catch(function (err) {
			var data = {
				status: false,
				message: err
			}
			return Promise.resolve(data);
		})
	}

	// добавление связи Материал-Работы
	addMaterialToWorkflow(formData) {

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
			method: "POST",
			body: formData
		})
		.then((response) => response.json())
		.then(function (data) {
			return Promise.resolve(data);
		})
		.catch(function (err) {
			var data = {
				status: false,
				message: err
			}
			return Promise.resolve(data);
		})
	}

	// добавление связи Работы-Работы
	addWorksToWorkflow(formData) {

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
			method: "POST",
			body: formData
		})
		.then((response) => response.json())
		.then(function (data) {
			return Promise.resolve(data);
		})
		.catch(function (err) {
			var data = {
				status: false,
				message: err
			}
			return Promise.resolve(data);
		})
	}

	// удаление связи Материал-Работы
	removeObjectFromWorkflow(formData) {

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
			method: "POST",
			body: formData
		})
		.then((response) => response.json())
		.then(function (data) {
			return Promise.resolve(data);
		})
		.catch(function (err) {
			var data = {
				status: false,
				message: err
			}
			return Promise.resolve(data);
		})
	}

	// добавление связи Расходы-Работы
	addExpenseToWorkflow(formData) {

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
			method: "POST",
			body: formData
		})
		.then((response) => response.json())
		.then(function (data) {
			return Promise.resolve(data);
		})
		.catch(function (err) {
			var data = {
				status: false,
				message: err
			}
			return Promise.resolve(data);
		})
	}

	// удаление связи Расходы-Работы
	removeExpenseFromWorkflow(formData) {

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
			method: "POST",
			body: formData
		})
		.then((response) => response.json())
		.then(function (data) {
			return Promise.resolve(data);
		})
		.catch(function (err) {
			var data = {
				status: false,
				message: err
			}
			return Promise.resolve(data);
		})
	}

	getTemplateData(id) {
		const formData = new FormData();
		formData.append('action', 'getTemplateData');
		// formData.append('token', localStorage.pd_token);
		// formData.append('c_user_id', localStorage.starta_id);
		formData.append('id', id);

		return fetch(process.env.VUE_APP_URL + "/jwt/api/estimates.php", {
				method: "POST",
				body: formData
			})
			.then((response) => response.json())
			.then(function (data) {
				// console.log(data);
				return Promise.resolve(data);
			})
			.catch(function (err) {
				var data = {
					status: false,
					message: err
				}
				return Promise.resolve(data);
			})
	}
	
}