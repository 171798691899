<template>
  <page-title>Материалы и работы</page-title>
  <div class="container">
    <Toolbar class="mb-3 card">
      <template #start>
        <Button
          label="Добавить материал"
          icon="pi pi-plus"
          class="mr-2"
          @click="showNewMaterialDialog"
        />
        <Button
          label="Добавить работы"
          icon="pi pi-plus"
          class="mr-2 p-button-help"
          @click="showNewWorksDialog"
        />
      </template>

      <template #end>
        <!-- <Button icon="pi pi-search" class="mr-2" /> -->
        <Button
          label="Импорт"
          @click="openImportObjectsDialog"
          icon="pi pi-upload"
          class="mr-2 p-button-secondary"
        />
        <Button label="Экспорт" @click="exportObjects" icon="pi pi-download" class="p-button-info" />
      </template>
    </Toolbar>

    <div class="card materials_list">
      <DataTable
        :value="allObjects"
        v-model:filters="filters"
        filterDisplay="menu"
        :loading="loading" 
        class="p-datatable-sm"
        responsiveLayout="scroll"
        :paginator="true"
        :rows="30"
        showGridlines 
        :globalFilterFields="['name','article','vendor']"
        :lazy="true"
        ref="dt"
        :totalRecords="totalRecords"
        @page="onPage($event)"
        @sort="onSort($event)"
        @filter="onFilter($event)" 
      >
        <!-- <template #header>
          <div class="flex justify-content-between">
              <Button type="button" icon="pi pi-filter-slash" label="Сбросить" class="p-button-outlined p-button-sm" @click="clearFilter()"/>
              <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Поиск" class="p-inputtext-sm" />
              </span>
          </div>
        </template> -->
        <template #loading>
          Данные загружаются...
        </template>
        <template #empty>Материалы не найдены</template>
        <Column field="type" :sortable="true" style="text-align: center; width: 50px;">
          <template #body="slotProps">
            <i :class="formatType(slotProps.data.type)"></i>
          </template>
        </Column>
        <Column field="name" header="Наименование" :showFilterMatchModes="false" :showAddButton="false" :showClearButton="false" :showFilterOperator="false" :sortable="true">
          <template #filter="{filterModel}">
            <InputText type="text" v-model="filterModel.value" class="p-column-filter p-inputtext-sm" placeholder="Поиск по названию"/>
          </template>
        </Column>
        <Column field="article" header="Артикул (внутр)" :showFilterMatchModes="false" :showAddButton="false" :showClearButton="false" :showFilterOperator="false" :sortable="true">
          <template #filter="{filterModel}">
            <InputText type="text" v-model="filterModel.value" class="p-column-filter p-inputtext-sm" placeholder="Поиск по артикулу"/>
          </template>
        </Column>
        <Column field="unit" header="Ед.изм." :sortable="true"></Column>
        <Column field="price" header="Себестоимость" :sortable="true">
          <template #body="slotProps">
            <span>{{ formatCurrency(slotProps.data.price) }}</span>
          </template>
        </Column>
        <Column field="markup" header="Наценка, %" :sortable="true"></Column>
        <!-- <Column field="description" header="Описание"></Column> -->
        <Column field="modified" header="Дата изменения" :sortable="true"></Column>
        <Column field="vendor" header="Поставщик" :showFilterMatchModes="false" :showAddButton="false" :showClearButton="false" :showFilterOperator="false" :sortable="true">
          <template #filter="{filterModel}">
            <InputText type="text" v-model="filterModel.value" class="p-column-filter p-inputtext-sm" placeholder="Поиск по поставщику"/>
          </template>
        </Column>
        <Column field="vendor_article" header="Код"></Column>
        <Column>
          <template #body="slotProps">
            <Button
              @click="showEditMaterialDialog(slotProps.data.id)"
              icon="pi pi-pencil"
              class="p-button-rounded p-button p-button-outlined mr-2" v-if="slotProps.data.type == 'material'"
            />
            <Button
              @click="showEditWorksDialog(slotProps.data.id)"
              icon="pi pi-pencil"
              class="p-button-rounded p-button p-button-outlined mr-2" v-else
            />
            <Button
              @click="confirmDeleteObject($event, slotProps.data.id)"
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-outlined"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>

  <Dialog
    :header="
      editedMaterial.mode == 'new' ? 'Новый материал' : editedMaterial.name
    "
    v-model:visible="displayEditMaterialDialog"
    :style="{ width: '740px' }"
  >
    <div class="flex flex-wrap">
      <div class="col-7">
        <div class="form_field">
          <label for="editedMaterial_name">
            <span>Наименование <sup style="color: red;">*</sup></span>
            <InputText
              type="text"
              id="editedMaterial_name"
              class="w-full"
              v-model="editedMaterial.name"
              placeholder="Название материала"
            />
          </label>
        </div>
      </div>

      <div class="col-3">
        <div class="form_field">
          <label for="editedMaterial_article">
            <span>Артикул <sup style="color: red;">*</sup></span>
            <InputText
              type="text"
              id="editedMaterial_article"
              class="w-full"
              v-model="editedMaterial.article"
              placeholder="Артикул"
            />
          </label>
        </div>
      </div>
      <div class="col-2">
        <div class="form_field">
          <label for="editedMaterial_unit">
            <span>Ед. изм. <sup style="color: red;">*</sup></span>
            <Dropdown
              v-model="editedMaterial.unit"
              id="editedMaterial_unit"
              class="w-full"
              :options="m_units"
            >
              <template #value="slotProps">
                <div v-if="slotProps.value">
                  <span>{{ slotProps.value }}</span>
                </div>
                <span v-else>{{ slotProps.placeholder }}</span>
              </template>
              <template #option="slotProps">
                <div>
                  <div>{{ slotProps.option }}</div>
                </div>
              </template>
            </Dropdown>
          </label>
        </div>
      </div>
      <div class="col-3">
        <div class="form_field">
          <label for="editedMaterial_price">
            <span>Себестоимость</span>
            <InputNumber
              id="editedMaterial_price"
              v-model="editedMaterial.price"
              mode="currency"
              currency="RUB"
              locale="ru-RU"
              class="w-full"
            />
          </label>
        </div>
      </div>
      <div class="col-3">
        <div class="form_field">
          <label for="editedMaterial_markup">
            <span>Наценка <sup style="color: red;">*</sup></span>
            <InputNumber
              id="editedMaterial_markup"
              v-model="editedMaterial.markup"
              suffix="%"
              class="w-full"
            />
          </label>
        </div>
      </div>

      <div class="col-3">
        <div class="form_field">
          <label for="editedMaterial_vendor">
            <span>Поставщик</span>
            <InputText
              type="text"
              id="editedMaterial_vendor"
              class="w-full"
              v-model="editedMaterial.vendor"
              placeholder="Название компании"
            />
          </label>
        </div>
      </div>
      <div class="col-3">
        <div class="form_field">
          <label for="editedMaterial_vendor_article">
            <span>Арт. поставщика</span>
            <InputText
              type="text"
              id="editedMaterial_vendor_article"
              class="w-full"
              v-model="editedMaterial.vendor_article"
            />
          </label>
        </div>
      </div>

      <div class="col-12">
        <div class="form_field">
          <label for="editedMaterial_description">
            <span>Описание</span>
            <Textarea
              id="editedMaterial_description"
              class="w-full"
              v-model="editedMaterial.description"
              rows="3"
              cols="50"
            />
          </label>
        </div>
      </div>

      <div class="col-12">
        <DataTable
          :value="editedMaterial.relations"
          :class="editedMaterial.mode == 'upd' ? 'p-datatable-sm' : 'p-datatable-sm p-disabled'"
          responsiveLayout="scroll"
        >
          <template #header>
            <div class="flex align-items-center">
              <span class="mr-2">Связанные товары</span>
              <Button
                type="button"
                icon="pi pi-plus"
                :label="selectedProduct ? selectedProduct.name : 'Добавить связь'"
                @click="toggle"
                aria:haspopup="true"
                aria-controls="overlay_panel"
                class="p-button-sm"
                v-if="editedMaterial.mode == 'upd'"
              />
            </div>
          </template>
          <template #empty>
            <span v-if="editedMaterial.mode == 'upd'">Нет связанных материалов</span>
            <span v-else>Добавить связи можно только после создания материала</span>
          </template>
          <Column field="id" header="ID"></Column>
          <Column field="name" header="Наименование"></Column>
          <Column field="price" header="Себестоимость"></Column>
          <Column field="vendor" header="Поставщик"></Column>
          <Column>
            <template #body="setProps">
              <p
                class="dotted_link"
                @click="confirmRemoveRelation($event, setProps.data.id, editedMaterial.id)"
              >удалить</p>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <template #footer>
      <Button
        label="Отмена"
        icon="pi pi-times"
        @click="displayEditMaterialDialog = false"
        class="p-button-text"
      />
      <Button
        label="Создать"
        icon="pi pi-check"
        @click="createMaterial"
        v-if="editedMaterial.mode == 'new'"
        autofocus
      />
      <Button label="Сохранить" icon="pi pi-check" @click="updateMaterial" v-else autofocus />
    </template>
  </Dialog>

  <Dialog
    :header="
      editedWorks.mode == 'new' ? 'Новые работы' : editedWorks.name
    "
    v-model:visible="displayEditWorksDialog"
    :style="{ width: '740px' }"
  >
    <div class="flex flex-wrap">
      <div class="col-12">
        <div class="form_field">
          <label for="editedWorks_name">
            <span>Наименование <sup style="color: red;">*</sup></span>
            <InputText
              type="text"
              id="editedWorks_name"
              class="w-full"
              v-model="editedWorks.name"
              placeholder="Название работ"
            />
          </label>
        </div>
      </div>

      <div class="col-4">
        <div class="form_field">
          <label for="editedWorks_article">
            <span>Артикул <sup style="color: red;">*</sup></span>
            <InputText
              type="text"
              id="editedWorks_article"
              class="w-full"
              v-model="editedWorks.article"
              placeholder="Артикул"
            />
          </label>
        </div>
      </div>
      <div class="col-4">
        <div class="form_field">
          <label for="editedWorks_unit">
            <span>Ед. изм. <sup style="color: red;">*</sup></span>
            <Dropdown
              v-model="editedWorks.unit"
              id="editedWorks_unit"
              class="w-full"
              :options="w_units"
            >
              <template #value="slotProps">
                <div v-if="slotProps.value">
                  <span>{{ slotProps.value }}</span>
                </div>
                <span v-else>{{ slotProps.placeholder }}</span>
              </template>
              <template #option="slotProps">
                <div>
                  <div>{{ slotProps.option }}</div>
                </div>
              </template>
            </Dropdown>
          </label>
        </div>
      </div>
      <div class="col-4">
        <div class="form_field">
          <label for="editedWorks_price">
            <span>Себестоимость</span>
            <InputNumber
              id="editedWorks_price"
              v-model="editedWorks.price"
              mode="currency"
              currency="RUB"
              locale="ru-RU"
              class="w-full"
            />
          </label>
        </div>
      </div>
      <!-- <div class="col-3">
        <div class="form_field">
          <label for="editedMaterial_markup">
            <span>Наценка <sup style="color: red;">*</sup></span>
            <InputNumber
              id="editedMaterial_markup"
              v-model="editedMaterial.markup"
              suffix="%"
              class="w-full"
            />
          </label>
        </div>
      </div> -->

      <!-- <div class="col-3">
        <div class="form_field">
          <label for="editedMaterial_vendor">
            <span>Поставщик</span>
            <InputText
              type="text"
              id="editedMaterial_vendor"
              class="w-full"
              v-model="editedMaterial.vendor"
              placeholder="Название компании"
            />
          </label>
        </div>
      </div> -->
      <!-- <div class="col-3">
        <div class="form_field">
          <label for="editedMaterial_vendor_article">
            <span>Арт. поставщика</span>
            <InputText
              type="text"
              id="editedMaterial_vendor_article"
              class="w-full"
              v-model="editedMaterial.vendor_article"
            />
          </label>
        </div>
      </div> -->

      <div class="col-12">
        <div class="form_field">
          <label for="editedWorks_description">
            <span>Описание</span>
            <Textarea
              id="editedWorks_description"
              class="w-full"
              v-model="editedWorks.description"
              rows="3"
              cols="50"
            />
          </label>
        </div>
      </div>

      <!-- <div class="col-12">
        <DataTable
          :value="editedMaterial.relations"
          :class="editedMaterial.mode == 'upd' ? 'p-datatable-sm' : 'p-datatable-sm p-disabled'"
          responsiveLayout="scroll"
        >
          <template #header>
            <div class="flex align-items-center">
              <span class="mr-2">Связанные товары</span>
              <Button
                type="button"
                icon="pi pi-plus"
                :label="selectedProduct ? selectedProduct.name : 'Добавить связь'"
                @click="toggle"
                aria:haspopup="true"
                aria-controls="overlay_panel"
                class="p-button-sm"
                v-if="editedMaterial.mode == 'upd'"
              />
            </div>
          </template>
          <template #empty>
            <span v-if="editedMaterial.mode == 'upd'">Нет связанных материалов</span>
            <span v-else>Добавить связи можно только после создания материала</span>
          </template>
          <Column field="id" header="ID"></Column>
          <Column field="name" header="Наименование"></Column>
          <Column field="price" header="Себестоимость"></Column>
          <Column field="vendor" header="Поставщик"></Column>
          <Column>
            <template #body="setProps">
              <p
                class="dotted_link"
                @click="confirmRemoveRelation($event, setProps.data.id, editedMaterial.id)"
              >удалить</p>
            </template>
          </Column>
        </DataTable>
      </div> -->
    </div>

    <template #footer>
      <Button
        label="Отмена"
        icon="pi pi-times"
        @click="displayEditWorksDialog = false"
        class="p-button-text"
      />
      <Button
        label="Создать"
        icon="pi pi-check"
        @click="createWorks"
        v-if="editedWorks.mode == 'new'"
        autofocus
      />
      <Button label="Сохранить" icon="pi pi-check" @click="updateWorks" v-else autofocus />
    </template>
  </Dialog>

  <OverlayPanel
    ref="op"
    appendTo="body"
    :showCloseIcon="true"
    id="overlay_panel"
    style="width: 450px"
    :breakpoints="{'960px': '75vw'}"
  >
    <DataTable
      :value="preparedMaterialsToRelate"
      v-model:selection="tempRelations"
      selectionMode="single"
      :paginator="true"
      :rows="5"
      @rowSelect="onProductSelect"
      responsiveLayout="scroll"
      :rowClass="rowClass"
    >
      <Column field="id" header="ID"></Column>
      <Column field="name" header="Наименование"></Column>
      <Column field="price" header="Стоимость">
        <template #body="slotProps">{{formatCurrency(slotProps.data.price)}}</template>
      </Column>
    </DataTable>
  </OverlayPanel>

  <Dialog
    header="Импорт позиций"
    v-model:visible="importObjectsDialogVisible"
    :style="{ width: '100%', 'max-width':'80vw' }"
  >
    <div class="flex flex-wrap">
      <div class="col-12" v-if="loadedObjects.length == 0">
        <div class="form_field">
          <FileUpload
            mode="basic"
            name="upload_objects[]"
            :url='handlerUrl'
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            :maxFileSize="10000000"
            chooseLabel="Выбрать файл"
            :customUpload="true"
            @uploader="onUpload"
            :auto="true"
            aria-describedby="fileupload-help"
          />
          <small id="fileupload-help">Допускается только формат .xlsx <a href="data/import_tpl.xlsx" download>[скачать шаблон файла]</a></small>
        </div>
      </div>

      <div class="col-12">
        <DataTable
          :value="loadedObjects"
          responsiveLayout="scroll"
          :class="loadedObjects.length > 0 ? 'p-datatable-sm' : 'p-disabled p-datatable-sm'"
          :rowClass="rowClassImport"
          showGridlines 
        >
          <template #header v-if="loadedObjects.length > 0">
            <div class="flex align-items-center">
              <span class="mr-2">Загруженные позиции</span> <span class="py-1 px-2 mr-2 font-normal">
                Всего:
                <b>{{loadedObjects.length}}</b>
              </span>
              <span class="py-1 px-2 mr-2 import_material_update font-normal">
                Будет обновлено:
                <b>{{loadedToUpdate}}</b>
              </span>
              <span class="py-1 px-2 import_material_new font-normal">
                Добавится новых:
                <b>{{loadedToCreate}}</b>
              </span>
            </div>
          </template>
          <Column field="name" header="Наименование" style="width: 30%"></Column>
          <Column field="article" header="Артикул (внутр.)"></Column>
          <Column field="unit" header="Ед.изм."></Column>
          <Column field="price" header="Себестоимость" style="text-align: right;">
            <template #body="slotProps">
              <span>{{ formatCurrency(slotProps.data.price) }}</span>
            </template>
          </Column>
          <Column field="markup" header="Наценка"></Column>
          <Column field="vendor" header="Поставщик"></Column>
          <Column field="vendor_article" header="Код"></Column>
          <Column field="type" style="text-align: center; width: 50px;">
            <template #body="slotProps">
              <i :class="formatType(slotProps.data.type)"></i>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <template #footer>
      <Button
        label="Отмена"
        icon="pi pi-times"
        @click="importObjectsDialogVisible = false"
        class="p-button-text"
      />
      <Button
        label="Импортировать"
        icon="pi pi-upload"
        @click="importObjects"
        :class="loadedObjects.length > 0 ? '' : 'p-disabled'"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import EstimateService from "../services/EstimateService";
import FilesService from "../services/FilesService";
import {FilterMatchMode} from 'primevue/api';

export default {
  props: ["userData"],
  data() {
    return {
      displayEditMaterialDialog: false,
      displayEditWorksDialog: false,
      totalRecords: 0,
      lazyParams: {},
      handlerUrl: process.env.VUE_APP_URL + "/jwt/api/upload_materials.php",
      editedMaterial: {
        mode: null,
        id: null,
        name: null,
        article: null,
        old_article: null,
        unit: null,
        price: null,
        markup: null,
        vendor: null,
        vendor_article: null,
        relations: null,
        description: null,
      },
      editedWorks: {
        mode: null,
        id: null,
        name: null,
        article: null,
        old_article: null,
        unit: null,
        price: null,
        markup: 0,
        vendor: null,
        vendor_article: null,
        // relations: null,
        description: null,
      },
      tempRelations: [],
      m_units: ["шт", "м.п.", "м2", "м3", "усл.", "мех/час", "мех/день", "чел/час", "чел/день", "смен", "мес", "выезд", "рейс", "%", "кг", "т", "рулон", "лист", "упак.", "л"],
      w_units: ["шт", "м.п.", "м2", "м3", "усл.", "мех/час", "мех/день", "чел/час", "чел/день", "смен", "мес", "выезд", "рейс", "%", "кг", "т", "рулон", "лист", "упак.", "л"],
      allObjects: [],
      importObjectsDialogVisible: false,
      loadedObjects: [],
      loadedToUpdate: null,
      loadedToCreate: null,
      filters: null,
      loading: true,
    };
  },
  EstimateService: null,
  FilesService: null,
  computed: {
    preparedMaterialsToRelate() {
      const that = this;

      var intersectionMaterials = this.editedMaterial.relations.map(function (
        v
      ) {
        return Object.assign(
          {},
          that.allObjects.find(function (v1) {
            return v1.id === v.id;
          }),
          v
        );
      });

      let output = [];

      // console.log(intersectionMaterials);

      this.allObjects.map((item) => {
        if (
          intersectionMaterials.find(
            (itemMaterial) => itemMaterial.id == item.id
          )
        ) {
          item.class = "p-disabled";
        } else {
          item.class = "";
        }
        output.push(item);
      });

      output.find((current) => {
        if (current.id == this.editedMaterial.id) {
          current.class = "p-disabled";
        }
      });

      // console.log(output);

      return output;
    },
  },
  created() {
    this.EstimateService = new EstimateService();
    this.FilesService = new FilesService();
    this.initFilters();
  },
  mounted() {
    let checkUserData = setInterval(() => {
      if (this.userData != null) {
        this.getAllObjects();
        clearInterval(checkUserData);
      }
    }, 200);

    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters
    };

  },
  methods: {
    rowClassImport(data) {
      // стилизация строки в таблице материалов
      return data.status === "update"
        ? "import_material_update"
        : "import_material_new";
    },
    onUpload(event) {
      let files = event.files[0];
      const that = this;

      const formData = new FormData();
      formData.append("action", "checkFile");
      formData.append("files", files);
      this.FilesService.checkObjectsFile(formData).then((data) => {
        // console.log(data);
        if (data.status) {
          that.loadedObjects = data.data;
          that.calcLoadedData();
        }
      });
    },
    openImportObjectsDialog() {
      this.loadedObjects = [];
      this.importObjectsDialogVisible = true;
    },
    importObjects() {
      const formData = new FormData();
      const that = this;

      formData.append("action", "importObjects");
      formData.append("materials", JSON.stringify(this.loadedObjects));

      this.FilesService.importObjects(formData).then((data) => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 3000,
          });
          that.getAllObjects();
          that.loadedObjects = [];
          that.importObjectsDialogVisible = false;
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    },
    calcLoadedData() {
      let toUpdate = 0;
      let toCreate = 0;
      for (let object of this.loadedObjects) {
        if (object.status == 'update') {
          toUpdate = toUpdate + 1;
        } else {
          toCreate = toCreate + 1;
        }
      }

      this.loadedToUpdate = toUpdate;
      this.loadedToCreate = toCreate;
    },
    rowClass(data) {
      // стилизация строки в таблице материалов
      return data.class === "p-disabled" ? "p-disabled" : null;
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    onProductSelect(event) {
      this.$refs.op.hide();
      this.editedMaterial.relations.push(event.data);
      //this.$toast.add({severity:'info', summary: 'Product Selected', detail: event.data.name, life: 3000});
    },
    onPage(event) {
      this.lazyParams = event;
      this.getAllObjects();
    },
    onSort(event) {
      this.lazyParams = event;
      this.getAllObjects();
    },
    onFilter() {
      this.lazyParams.filters = this.filters;
      this.getAllObjects();
    },
    getAllObjects() {
      // получение всех материалов из БД
      this.EstimateService.getAllObjects(JSON.stringify( this.lazyParams )).then((data) => {
        // console.log(data);
        if (data.status) {
          this.allObjects = data.message;
          this.totalRecords = data.totalRecords;
          this.loading = false;
        }
      });
    },
    formatCurrency(value) {
      let price = Number(value);
      return price.toLocaleString("ru-RU", {
        style: "currency",
        currency: "RUB",
      });
    },
    formatType(value) {
      let output = '';
      switch (value) {
        case 'material':
          output = 'pi pi-th-large';
          break;
        case 'works':
          output = 'pi pi-user';
          break;
      }
      return output;
    },
    showEditMaterialDialog(m_id) {
      const that = this;
      for (let material of this.allObjects) {
        if (material.id == m_id) {
          that.editedMaterial = {
            mode: "upd",
            id: material.id,
            name: material.name,
            article: material.article,
            old_article: material.article,
            unit: material.unit,
            price: material.price,
            markup: material.markup,
            description: material.description,
            vendor: material.vendor,
            related: material.related,
            vendor_article: material.vendor_article,
            relations: [],
          };
          that.getMaterialRelations(m_id);
        }
      }
      this.displayEditMaterialDialog = true;
    },
    showEditWorksDialog(w_id) {
      const that = this;
      for (let works of this.allObjects) {
        if (works.id == w_id) {
          that.editedWorks = {
            mode: "upd",
            id: works.id,
            name: works.name,
            article: works.article,
            old_article: works.article,
            unit: works.unit,
            price: works.price,
            // markup: material.markup,
            description: works.description,
            // vendor: material.vendor,
            // related: material.related,
            // vendor_article: material.vendor_article,
            // relations: [],
          };
          // that.getMaterialRelations(m_id);
        }
      }
      this.displayEditWorksDialog = true;
    },
    showNewMaterialDialog() {
      (this.editedMaterial = {
        mode: "new",
        id: null,
        name: null,
        article: null,
        unit: null,
        price: null,
        markup: null,
        vendor: null,
        vendor_article: null,
        description: null,
        relations: null,
      }),
        (this.displayEditMaterialDialog = true);
    },
    showNewWorksDialog() {
      (this.editedWorks = {
        mode: "new",
        id: null,
        name: null,
        article: null,
        unit: null,
        price: null,
        markup: 0,
        // vendor: null,
        // vendor_article: null,
        description: null,
        // relations: null,
      }),
        (this.displayEditWorksDialog = true);
    },
    confirmDeleteObject(event, m_id) {
      // запрос подтверждения удаления объекта
      const that = this;
      this.$confirm.require({
        target: event.currentTarget,
        header: "Подтверждение",
        message: "Удалить?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          that.removeObject(m_id);
        },
      });
    },
    removeObject(id) {
      // удаление объекта по ID
      const that = this;
      this.EstimateService.removeObject(id).then((data) => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 3000,
          });
          that.getAllObjects();
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    },
    updateMaterial() {
      const that = this;
      if (!this.editedMaterial.price || this.editedMaterial.price == '') {
        this.editedMaterial.price = 0;
      }
      if (this.editedMaterial.name && this.editedMaterial.article && this.editedMaterial.unit && this.editedMaterial.price && this.editedMaterial.markup) {
        const formData = new FormData();
        formData.append("action", "updateMaterial");
        formData.append("id", this.editedMaterial.id);
        formData.append("name", this.editedMaterial.name);
        formData.append("article", this.editedMaterial.article);
        formData.append("old_article", this.editedMaterial.old_article);
        formData.append("unit", this.editedMaterial.unit);
        formData.append("price", this.editedMaterial.price);
        formData.append("markup", this.editedMaterial.markup);
        formData.append("description", this.editedMaterial.description);
        formData.append("vendor", this.editedMaterial.vendor);
        formData.append("type", 'material');
        formData.append("vendor_article", this.editedMaterial.vendor_article);
        formData.append(
          "relations",
          JSON.stringify(this.editedMaterial.relations)
        );

        this.EstimateService.updateMaterial(formData).then((data) => {
          if (data.status) {
            that.$toast.add({
              severity: "success",
              detail: data.message,
              life: 3000,
            });
            that.displayEditMaterialDialog = false;
            that.getAllObjects();
          } else {
            that.$toast.add({
              severity: "error",
              detail: data.message,
              life: 3000,
            });
          }
        });
      } else {
        that.$toast.add({
          severity: "warn",
          detail: 'Не заполнены обязательные поля',
          life: 3000,
        });
      }
    },
    createMaterial() {
      const that = this;
      if (!this.editedMaterial.price || this.editedMaterial.price == '') {
        this.editedMaterial.price = 0;
      }
      if (this.editedMaterial.name && this.editedMaterial.article && this.editedMaterial.unit && this.editedMaterial.price && this.editedMaterial.markup) {
        const formData = new FormData();
        formData.append("action", "createMaterial");
        //   formData.append("id", this.editedMaterial.id);
        formData.append("name", this.editedMaterial.name);
        formData.append("article", this.editedMaterial.article);
        formData.append("unit", this.editedMaterial.unit);
        formData.append("price", this.editedMaterial.price);
        formData.append("markup", this.editedMaterial.markup);
        formData.append("description", this.editedMaterial.description);
        formData.append("vendor", this.editedMaterial.vendor);
        formData.append("vendor_article", this.editedMaterial.vendor_article);
        formData.append("relations", this.editedMaterial.relations);

        this.EstimateService.createMaterial(formData).then((data) => {
          if (data.status) {
            that.$toast.add({
              severity: "success",
              detail: data.message,
              life: 3000,
            });
            that.displayEditMaterialDialog = false;
            that.getAllObjects();
          } else {
            that.$toast.add({
              severity: "error",
              detail: data.message,
              life: 3000,
            });
          }
        });
      } else {
        that.$toast.add({
          severity: "warn",
          detail: 'Не заполнены обязательные поля',
          life: 3000,
        });
      }
    },
    createWorks() {
      const that = this;
      if (!this.editedWorks.price || this.editedWorks.price == '') {
        this.editedWorks.price = 0;
      }
      if (this.editedWorks.name && this.editedWorks.article && this.editedWorks.unit && this.editedWorks.price) {
        const formData = new FormData();
        formData.append("action", "createWorks");
        //   formData.append("id", this.editedMaterial.id);
        formData.append("name", this.editedWorks.name);
        formData.append("article", this.editedWorks.article);
        formData.append("unit", this.editedWorks.unit);
        formData.append("price", this.editedWorks.price);
        // formData.append("markup", this.editedWorks.markup);
        formData.append("description", this.editedWorks.description);
        // formData.append("vendor", this.editedMaterial.vendor);
        // formData.append("vendor_article", this.editedMaterial.vendor_article);
        // formData.append("relations", this.editedMaterial.relations);

        this.EstimateService.createWorks(formData).then((data) => {
          if (data.status) {
            that.$toast.add({
              severity: "success",
              detail: data.message,
              life: 3000,
            });
            that.displayEditWorksDialog = false;
            that.getAllObjects();
          } else {
            that.$toast.add({
              severity: "error",
              detail: data.message,
              life: 3000,
            });
          }
        });
      } else {
        that.$toast.add({
          severity: "warn",
          detail: 'Не заполнены обязательные поля',
          life: 3000,
        });
      }
    },
    updateWorks() {
      const that = this;
      if (!this.editedWorks.price || this.editedWorks.price == '') {
        this.editedWorks.price = 0;
      }
      if (this.editedWorks.name && this.editedWorks.article && this.editedWorks.unit && this.editedWorks.price) {
        const formData = new FormData();
        formData.append("action", "updateWorks");
        formData.append("id", this.editedWorks.id);
        formData.append("name", this.editedWorks.name);
        formData.append("article", this.editedWorks.article);
        formData.append("old_article", this.editedWorks.old_article);
        formData.append("unit", this.editedWorks.unit);
        formData.append("price", this.editedWorks.price);
        // formData.append("markup", this.editedMaterial.markup);
        formData.append("description", this.editedWorks.description);
        // formData.append("vendor", this.editedMaterial.vendor);
        formData.append("type", 'works');
        // formData.append("vendor_article", this.editedMaterial.vendor_article);
        // formData.append(
        //   "relations",
        //   JSON.stringify(this.editedMaterial.relations)
        // );

        this.EstimateService.updateWorks(formData).then((data) => {
          if (data.status) {
            that.$toast.add({
              severity: "success",
              detail: data.message,
              life: 3000,
            });
            that.displayEditWorksDialog = false;
            that.getAllObjects();
          } else {
            that.$toast.add({
              severity: "error",
              detail: data.message,
              life: 3000,
            });
          }
        });
      } else {
        that.$toast.add({
          severity: "warn",
          detail: 'Не заполнены обязательные поля',
          life: 3000,
        });
      }
    },
    getMaterialRelations(id) {
      const that = this;
      this.EstimateService.getMaterialRelations(id).then((data) => {
        if (data.status) {
          that.tempRelations = [];
          for (let rm of data.message) {
            for (let m of that.allObjects) {
              if (Number(m.id) === Number(rm.related_product)) {
                console.log("Найдено. Добавляем.");
                that.tempRelations.push(m);
              }
            }
          }
          that.editedMaterial.relations = that.tempRelations;
        }
      });
    },
    confirmRemoveRelation(event, related_product, product_id) {
      // запрос подтверждения удаления связи
      console.log(related_product, product_id);
      const that = this;
      this.$confirm.require({
        target: event.currentTarget,
        message: "Удалить эту связь?",
        header: "Подтверждение",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          that.removeRelation(related_product, product_id);
        },
      });
    },
    removeRelation(related_product, product_id) {
      // удаление связи по ID товара и ID связанного товара
      // console.log(related_product, product_id);
      const that = this;
      this.EstimateService.removeRelation(related_product, product_id).then(
        (data) => {
          if (data.status) {
            that.$toast.add({
              severity: "success",
              detail: data.message,
              life: 3000,
            });
            that.getMaterialRelations(product_id);
          } else {
            that.$toast.add({
              severity: "error",
              detail: data.message,
              life: 3000,
            });
          }
        }
      );
    },
    exportObjects() {
      this.FilesService.exportObjects().then(data => {
        if (data.status) {
          location.href = process.env.VUE_APP_URL + '/jwt/api/' + data.message;
        } else {
          this.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      })
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'name': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'article': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'vendor': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    }
  },
};
</script>

<style lang="scss">
.p-toolbar {
  border: none;
}

.materials-list {
  .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 3px;
  }

  .form_field label .p-inputtext {
    width: 100%;
  }

  
}
.p-datatable .p-column-header-content .p-column-title {
    font-size: 14px;
  }

.dotted_link {
  line-height: 1;
  margin: 0;
  color: $darkgray_color;
  border-bottom: 1px $darkgray_color dashed;
  display: table;
  cursor: pointer;
  &:hover {
    color: $error_color;
    border-color: $error_color;
  }
}

.import_material_update {
  background: var(--orange-100) !important;
}

.import_material_new {
  background: var(--green-100) !important;
}
</style>