<template>
  <page-title>Пользователи</page-title>
  <div class="container">
    <Toolbar class="mb-3 card">
      <template #start>
        <Button
          label="Новый пользователь"
          icon="pi pi-plus"
          class="mr-2"
          @click="openUserDialog"
        />
      </template>

      <template #end>
        <!-- <Button icon="pi pi-search" class="mr-2" />
            <Button icon="pi pi-calendar" class="p-button-success mr-2" />
        <Button icon="pi pi-times" class="p-button-danger" />-->
      </template>
    </Toolbar>
    <div class="card">
      <DataTable
        :value="users"
        class="p-datatable-sm"
        responsiveLayout="scroll"
        :paginator="true"
        :rows="30"
        showGridlines 
      >
        <template #empty> Пользователи не найдены </template>
        <Column field="id" header="ID"></Column>
        <Column header="Имя и фамилия">
          <template #body="slotProps">
            <span
              >{{ slotProps.data.firstname }}
              {{ slotProps.data.lastname }}</span
            >
          </template>
        </Column>
        <Column field="email" header="E-mail"></Column>
        <Column field="modified" header="Изменён"></Column>
        <Column field="permissions" header="Группа">
          <template #body="slotProps">
            <span>{{ userPermissions(slotProps.data.permissions) }}</span>
          </template>
        </Column>
        <Column field="role" header="Должность">
          <template #body="slotProps">
            <span>{{ userRole(slotProps.data.role) }}</span>
          </template>
        </Column>
        <Column field="active" header="Статус">
          <template #body="slotProps">
            <Tag severity="success" value="Активен" v-if="slotProps.data.active == 1"></Tag>
            <Tag severity="danger" value="Заблокирован" v-else></Tag>
          </template>
        </Column>
        <Column class="user_controls_column" header="Управление">
          <template #body="slotProps">
            <div class="wrap">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                title="Редактировать"
                @click="showUpdateUserDialog(slotProps.data.id)"
              />
              <Button
                icon="pi pi-refresh"
                class="p-button-rounded p-button-warning mr-2"
                title="Сбросить пароль"
                @click="confirmResetPassword(slotProps.data.id)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger mr-2"
                title="Удалить"
                @click="confirmRemoveUser(slotProps.data.id)"
              />
              <Button
                icon="pi pi-sign-out"
                :class="
                  'p-button-rounded p-button-' +
                  stylingReauthBtn(slotProps.data.reauth)
                "
                title="Сбросить авторизацию"
                @click="reauthUser(slotProps.data.id)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>

  <Dialog
    v-model:visible="userDialogVisible"
    :style="{ width: '640px' }"
    :header="userDialogTitle"
    :modal="true"
    class="p-fluid"
  >
    <div class="grid">
      <div class="field col-12 sm:col-6">
        <label for="firstname">Имя</label>
        <InputText
          id="firstname"
          v-model.trim="userParams.firstname"
          required="true"
          autofocus
        />
      </div>
      <div class="field col-12 sm:col-6">
        <label for="lastname">Фамилия</label>
        <InputText
          id="lastname"
          v-model.trim="userParams.lastname"
          required="true"
          autofocus
        />
      </div>
      <div class="field col-12 sm:col-6">
        <label for="email">Email</label>
        <InputText
          id="email"
          v-model.trim="userParams.email"
          required="true"
          autofocus
        />
      </div>
      <div class="field col-12 sm:col-6">
        <label for="password" v-if="userMode === 'new'">Пароль</label>
        <InputText
          id="password"
          v-model.trim="userParams.password"
          required="true"
          autofocus v-if="userMode === 'new'"
        />
      </div>
      <div class="fields col-12 p-0 flex">
        <div class="field col-6">
          <label for="permissions" class="mb-3">Группа (права пользователя)</label>
          <Dropdown
            id="permissions"
            v-model="userParams.permissions"
            :options="permissonsData"
            optionLabel="label"
            optionValue="value"
            placeholder="Выберите группу"
            @change="confirmAdmin"
          >
            <template #value="slotProps">
              <div v-if="slotProps.value && slotProps.value.value">
                <span>{{ slotProps.value.label }}</span>
              </div>
              <div v-else-if="slotProps.value && !slotProps.value.value">
                <span>{{ slotProps.value }}</span>
              </div>
              <span v-else>{{ slotProps.placeholder }}</span>
            </template>
          </Dropdown>
        </div>
        <div class="field col-6">
          <label for="role" class="mb-3">Роль пользователя</label>
          <Dropdown
            id="role"
            v-model="userParams.role"
            :options="rolesData"
            optionLabel="label"
            optionValue="value"
            placeholder="Выберите роль"
          >
            <template #value="slotProps">
              <div v-if="slotProps.value && slotProps.value.value">
                {{ slotProps.value.label }}
              </div>
              <div v-else-if="slotProps.value && !slotProps.value.value">
                {{ slotProps.value }}
              </div>
              <span v-else>{{ slotProps.placeholder }}</span>
            </template>
          </Dropdown>
        </div>
      </div>
      <div class="field col-12 sm:col-6">
        <label for="active">Активный</label>
        <br />
        <InputSwitch
          v-model="userParams.active"
          :trueValue="1"
          :falseValue="0"
        />
      </div>
    </div>
    <template #footer>
      <Button
        label="Отмена"
        icon="pi pi-times"
        class="p-button-secondary p-button-text"
        @click="hideUserDialog"
      />
      <Button
        label="Создать"
        icon="pi pi-check"
        class="p-button-raised"
        @click="createUser"
        v-if="userMode === 'new'"
      />
      <Button
        label="Сохранить"
        icon="pi pi-check"
        class="p-button-raised"
        @click="updateUser"
        v-else
      />
    </template>
  </Dialog>
</template>

<script>
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";

export default {
  props: ["userData"],
  data() {
    return {
      users: null,
      userDialogVisible: false,
      userMode: "new",
      userParams: {
        id: null,
        firstname: null,
        lastname: null,
        email: null,
        password: null,
        active: 1,
        permissions: null,
        role: null,
        reauth: 0,
      },
      permissonsData: null,
      rolesData: null,
    };
  },
  AuthService: null,
  UserService: null,
  created() {
    this.AuthService = new AuthService();
    this.UserService = new UserService();
    this.AuthService.getRolesData().then(
      (data) => (this.rolesData = data.data)
    );
    this.AuthService.getGroupsData().then(
      (data) => (this.permissonsData = data.data)
    );
  },
  mounted() {
    let checkUserData = setInterval(() => {
      if (this.userData != null) {
        this.getAllUsers();
        clearInterval(checkUserData);
      }
    }, 200);
  },
  computed: {
    userDialogTitle() {
      if (this.userMode === "new") {
        return "Новый пользователь";
      } else {
        return this.userParams.firstname + " " + this.userParams.lastname;
      }
    },
  },
  methods: {
    stylingReauthBtn(value) {
      if (value == 1) {
        return 'secondary p-disabled';
      } else {
        return 'help';
      }
    },
    userPermissions(name) {
      for (let user of this.permissonsData) {
        if (user.value == name) {
          return user.label;
        }
      }
    },
    userRole(name) {
      for (let user of this.rolesData) {
        if (user.value == name) {
          return user.label;
        }
      }
    },
    clearUserParams() {
      this.UserParams = {
        id: null,
        firstname: null,
        lastname: null,
        email: null,
        password: null,
        active: 1,
        permissions: null,
        role: null,
        reauth: 0,
      };
    },
    openUserDialog() {
      this.userDialogVisible = true;
    },
    hideUserDialog() {
      this.userDialogVisible = false;
    },
    createUser() {
      const that = this;
      if (
        this.userParams.firstname &&
        this.userParams.lastname &&
        this.userParams.email &&
        this.userParams.password &&
        this.userParams.permissions &&
        this.userParams.role
      ) {
        const formData = new FormData();
        var token = this.UserService.getCookie("pd_token");

        formData.append("action", "createUser");
        formData.append("token", token);
        formData.append("firstname", this.userParams.firstname);
        formData.append("lastname", this.userParams.lastname);
        formData.append("email", this.userParams.email);
        formData.append("password", this.userParams.password);
        formData.append("permissions", this.userParams.permissions);
        formData.append("active", this.userParams.active);
        formData.append("role", this.userParams.role);

        this.UserService.createUser(formData).then((data) => {
          if (data.status) {
            that.userMode = "new";
            that.userDialogVisible = false;
            that.getAllUsers();
            that.$toast.add({
              severity: "success",
              summary: data.message,
              life: 4000,
            });
            this.clearUserParams();
          } else {
            that.$toast.add({
              severity: "error",
              detail: data.message,
              life: 4000,
            });
          }
        });
      } else {
        that.$toast.add({
          severity: "warn",
          detail: "Не заполнены обязательные поля",
          life: 4000,
        });
      }
    },
    updateUser() {
      const that = this;
      if (
        this.userParams.firstname &&
        this.userParams.lastname &&
        this.userParams.email &&
        this.userParams.permissions &&
        this.userParams.role
      ) {
        const formData = new FormData();
        var token = this.UserService.getCookie("pd_token");

        formData.append("action", "updateUser");
        formData.append("token", token);
        formData.append("id", this.userParams.id);
        formData.append("firstname", this.userParams.firstname);
        formData.append("lastname", this.userParams.lastname);
        formData.append("email", this.userParams.email);
        formData.append("permissions", this.userParams.permissions);
        formData.append("active", this.userParams.active);
        formData.append("role", this.userParams.role);
        formData.append("reauth", 1);

        this.UserService.updateUser(formData).then((data) => {
          if (data.status) {
            that.clearUserParams();
            that.userMode = "new";
            that.userDialogVisible = false;
            that.getAllUsers();
            that.$toast.add({
              severity: "success",
              detail: data.message,
              life: 4000,
            });
            this.clearUserParams();
          } else {
            that.$toast.add({
              severity: "error",
              detail: data.message,
              life: 4000,
            });
          }
        });
      } else {
        that.$toast.add({
          severity: "warn",
          detail: "Не заполнены обязательные поля",
          life: 4000,
        });
      }
    },
    getAllUsers() {
      // получение всех пользователей из БД
      const that = this;
      this.UserService.getAllUsers().then((data) => {
        if (data.status) {
          that.users = data.message;
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    confirmAdmin(e) {
      const that = this;
      if (e.value === "admin") {
        this.$confirm.require({
          message:
            "Вы уверены, что хотите дать права администратора этому пользователю?",
          header: "Группа пользователя",
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            that.userParams.permissions = "admin";
          },
          reject: () => {
            that.userParams.permissions = "user";
          },
        });
      }
    },
    showUpdateUserDialog(id) {
      const that = this;
      this.userMode = "upd";
      this.UserService.getUserByID(id).then((data) => {
        if (data.status) {
          that.userParams = data.message;
          this.userDialogVisible = true;
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    confirmRemoveUser(id) {
      const that = this;
      this.$confirm.require({
        message: "Вы уверены, что хотите удалить пользователя?",
        header: "Удаление пользователя",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          that.removeUser(id);
        },
      });
    },
    removeUser(id) {
      const that = this;
      this.UserService.removeUser(id).then((data) => {
        if (data.status) {
          that.getAllUsers();
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 4000,
          });
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    resetPassword(id) {
      const that = this;
      this.UserService.resetPassword(id).then((data) => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            summary: data.message,
            detail: "Новый пароль: " + data.password,
            life: 6000,
          });
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    reauthUser(id) {
      const that = this;
      this.UserService.reauthUser(id).then((data) => {
        if (data.status) {
          that.getAllUsers();
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 6000,
          });
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    confirmResetPassword(id) {
      const that = this;
      this.$confirm.require({
        // target: event.currentTarget,
        message: "Вы уверены, что хотите сбросить пароль?",
        header: "Сброс пароля",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          that.resetPassword(id);
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.p-toolbar {
  border: none;
}
.p-tag {
  font-weight: 300;
}
</style>