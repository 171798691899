import {
    createRouter,
    createWebHistory
} from 'vue-router';
import Login from '../views/Login.vue';
import Users from '../views/Users.vue';
import UserEdit from '../views/UserEdit.vue';
import Dashboard from '../views/Dashboard.vue';
import EstimateEdit from '../views/EstimateEdit.vue';
import Estimates from '../views/Estimates.vue';
import Materials from '../views/Objects.vue';
import Expenses from '../views/Expenses.vue';
import Templates from '../views/Templates.vue';
import TemplateEdit from '../views/TemplateEdit.vue';
import Workflows from '../views/Workflows.vue';
import WorkflowEdit from '../views/WorkflowEdit.vue';
import Help from '../views/Help.vue';

const routes = [{
    path: '/auth',
    name: 'Авторизация',
    component: Login,
    meta: { title: "Авторизация", permissions: [], roles: [] }
}, {
    path: '/users',
    name: 'Пользователи',
    component: Users,
    meta: { title: "Список пользователей", permissions: ['admin'], roles: ['director', 'support'] }
}, {
    path: '/profile',
    name: 'Профиль пользователя',
    component: UserEdit,
    meta: { title: "Профиль пользователя", permissions: [], roles: [] }
}, {
    path: '/',
    name: 'Дашборд',
    component: Dashboard,
    meta: { title: "Дашборд", permissions: [], roles: [] }
}, {
    path: '/estimates/:id',
    name: 'Создание сметы',
    component: EstimateEdit,
    meta: {
        title: "Создание сметы",
        permissions: ['user', 'admin'],
        roles: ['director', 'estimator', 'manager', 'support', 'templater'],
    }

}, {
    path: '/estimates',
    name: 'Список смет',
    component: Estimates,
    meta: {
        title: "Список смет",
        permissions: ['user', 'admin'],
        roles: ['director', 'estimator', 'support', 'manager', 'templater'],
    }
}, {
    path: '/objects',
    name: 'База материалов и работ',
    component: Materials,
    meta: {
        title: "База материалов и работ",
        permissions: ['user', 'admin'],
        roles: ['director', 'supplier', 'support'],
    }
},
{
    path: '/expenses',
    name: 'Расходы',
    component: Expenses,
    meta: {
        title: "Расходы",
        permissions: ['user', 'admin'],
        roles: ['director', 'supplier', 'support'],
    }
},
{
    path: '/templates',
    name: 'Шаблоны смет',
    component: Templates,
    meta: {
        title: "Шаблоны смет",
        permissions: ['user', 'admin'],
        roles: ['director', 'support', 'templater'],
    }
},
{
    path: '/templates/:id',
    name: 'Шаблон сметы',
    component: TemplateEdit,
    meta: {
        title: "Редактирование шаблона",
        permissions: ['user', 'admin'],
        roles: ['director', 'support', 'templater'],
    }
},
{
    path: '/workflows',
    name: 'Разделы этапов',
    component: Workflows,
    meta: {
        title: "Разделы этапов",
        permissions: ['user', 'admin'],
        roles: ['director', 'support', 'templater'],
    }
},
{
    path: '/workflows/:id',
    name: 'Раздел этапа',
    component: WorkflowEdit,
    meta: {
        title: "Редактирование раздела",
        permissions: ['user', 'admin'],
        roles: ['director', 'support', 'templater'],
    }
},
{
    path: '/help',
    name: 'Помощь',
    label: 'Помощь',
    component: Help,
    meta: {
        title: "Помощь",
        permissions: [],
        roles: [],
    }
}
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;