<template>
  <page-title>Расходы</page-title>
  <div class="container">
    <Toolbar class="mb-3 card">
      <template #start>
        <Button
          label="Добавить расход"
          icon="pi pi-plus"
          class="mr-2"
          @click="showNewExpenseDialog"
        />
      </template>
    </Toolbar>

    <div class="card materials_list">
      <DataTable
        :value="allExpenses"
        class="p-datatable-sm"
        responsiveLayout="scroll"
        :paginator="true"
        :rows="30"
        showGridlines 
      >
        <template #empty> Расходы не найдены </template>
        <Column field="type" :sortable="true" style="text-align: center; width: 50px;">
          <template #body="slotProps">
            <i :class="formatType(slotProps.data.type)"></i>
          </template>
        </Column>
        <Column field="name" header="Наименование" :sortable="true"></Column>
        <Column field="markup" header="Наценка, %" :sortable="true"></Column>
        <Column field="modified" header="Дата изменения" :sortable="true"></Column>
        <Column>
          <template #body="slotProps">
            <Button
              @click="showEditExpenseDialog(slotProps.data.id)"
              icon="pi pi-pencil"
              class="p-button-rounded p-button p-button-outlined mr-2"
            />
            <Button
              @click="confirmDeleteExpense($event, slotProps.data.id)"
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-outlined"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>

  <Dialog
    :header="
      editedExpense.mode == 'new' ? 'Новый расход' : editedExpense.name
    "
    v-model:visible="displayEditExpenseDialog"
    :style="{ width: '740px' }"
  >
    <div class="flex flex-wrap">
      
      <div class="col-6">
        <div class="form_field">
          <label for="editedExpense_name"
            ><span>Наименование <sup style="color: red;">*</sup></span>
            <InputText
              type="text"
              id="editedExpense_name"
              class="w-full"
              v-model="editedExpense.name"
              placeholder="Название расходов"
            />
          </label>
        </div>
      </div>
      <div class="col-3">
        <div class="form_field">
          <label for="editedExpense_markup"
            ><span>Наценка <sup style="color: red;">*</sup></span>
            <InputNumber
              id="editedExpense_markup"
              v-model="editedExpense.markup"
              suffix="%"
              class="w-full"
            />
          </label>
        </div>
      </div>
      <div class="col-3">
        <div class="form_field">
          <label for="editedExpense_type"
            ><span>Тип <sup style="color: red;">*</sup></span>
            <Dropdown
              v-model="editedExpense.type"
              id="editedExpense_type"
              class="w-full"
              :options="e_types"
              optionLabel="name"
              optionValue="value"
            >
              <template #value="slotProps">
                <div v-if="slotProps.value">
                  <span>{{ slotProps.value }}</span>
                </div>
                <span v-else>{{ slotProps.placeholder }}</span>
              </template>
              <template #option="slotProps">
                <div>
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </label>
        </div>
      </div>
    </div>

    <template #footer>
      <Button
        label="Отмена"
        icon="pi pi-times"
        @click="displayEditExpenseDialog = false"
        class="p-button-text"
      />
      <Button
        label="Создать"
        icon="pi pi-check"
        @click="createExpense"
        v-if="editedExpense.mode == 'new'"
        autofocus
      />
      <Button
        label="Сохранить"
        icon="pi pi-check"
        @click="updateExpense"
        v-else
        autofocus
      />
    </template>
  </Dialog>

</template>

<script>
import EstimateService from "../services/EstimateService";

export default {
  props: ['userData'],
  data() {
    return {
      displayEditExpenseDialog: false,
      editedExpense: {
        mode: null,
        id: null,
        name: null,
        type: null,
        markup: null,
      },
      e_types: [{name: "Материалы", value: "material"},{name: "Работы", value: "works"}],
      allExpenses: [],
    };
  },
  EstimateService: null,

  created() {
    this.EstimateService = new EstimateService();
  },
  mounted() {
    let checkUserData = setInterval(() => {
      if (this.userData != null) {
        this.getAllExpenses();
        clearInterval(checkUserData);
      }
    }, 200);
  },
  methods: {
    formatType(value) {
      let output = '';
      switch (value) {
        case 'material':
          output = 'pi pi-th-large';
          break;
        case 'works':
          output = 'pi pi-user';
          break;
      }
      return output;
    },
    getAllExpenses() {
      // получение всех расходов из БД
      this.EstimateService.getAllExpenses().then((data) => {
        if (data.status) {
          this.allExpenses = data.message;
        }
      });
    },
    showEditExpenseDialog(m_id) {
      const that = this;
      for (let expense of this.allExpenses) {
        if (expense.id == m_id) {
          that.editedExpense = {
            mode: "upd",
            id: expense.id,
            name: expense.name,
            markup: expense.markup,
            type: expense.type,
          };
        }
      }
      this.displayEditExpenseDialog = true;
    },
    showNewExpenseDialog() {
      (this.editedExpense = {
        mode: "new",
        id: null,
        name: null,
        markup: null,
        type: null,
      }),
        (this.displayEditExpenseDialog = true);
    },
    confirmDeleteExpense(event, e_id) {
      // запрос подтверждения удаления материала
      const that = this;
      this.$confirm.require({
        target: event.currentTarget,
        header: "Подтверждение",
        message: "Удалить этот расход?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          that.removeExpense(e_id);
        },
      });
    },
    removeExpense(id) { // удаление расходы по ID
      const that = this;
      this.EstimateService.removeExpense(id).then(data => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 3000,
          });
          that.getAllExpenses();
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      })
    },
    updateExpense() {
      const that = this;
      if (this.editedExpense.id && this.editedExpense.name && this.editedExpense.markup > 0 && this.editedExpense.type) {
        const formData = new FormData();
        formData.append("action", "updateExpense");
        formData.append("id", this.editedExpense.id);
        formData.append("name", this.editedExpense.name);
        formData.append("markup", this.editedExpense.markup);
        formData.append("type", this.editedExpense.type);

        this.EstimateService.updateExpense(formData).then((data) => {
            if (data.status) {
            that.$toast.add({
                severity: "success",
                detail: data.message,
                life: 3000,
            });
            that.displayEditExpenseDialog = false;
            that.getAllExpenses();
            } else {
            that.$toast.add({
                severity: "error",
                detail: data.message,
                life: 3000,
            });
            }
        });
      } else {
        this.$toast.add({
            severity: "warn",
            detail: 'Необходимо заполнить все поля',
            life: 3000,
        });
      }
    },
    createExpense() {
      const that = this;
      if (this.editedExpense.name && this.editedExpense.markup > 0) {
        const formData = new FormData();
            formData.append("action", "createExpense");
            formData.append("name", this.editedExpense.name);
            formData.append("markup", this.editedExpense.markup);
            formData.append("type", this.editedExpense.type);

            this.EstimateService.createExpense(formData).then((data) => {
                if (data.status) {
                that.$toast.add({
                    severity: "success",
                    detail: data.message,
                    life: 3000,
                });
                that.displayEditExpenseDialog = false;
                that.getAllExpenses();
                } else {
                that.$toast.add({
                    severity: "error",
                    detail: data.message,
                    life: 3000,
                });
            }
        });   
      } else {
        this.$toast.add({
            severity: "warn",
            detail: 'Необходимо заполнить все поля',
            life: 3000,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.p-toolbar {
  border: none;
}

.materials-list {
  .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 3px;
  }

  .form_field label .p-inputtext {
    width: 100%;
  }
}

.dotted_link {
  line-height: 1;
  margin: 0;
  color: $darkgray_color;
  border-bottom: 1px $darkgray_color dashed;
  display: table;
  cursor: pointer;
  &:hover {
    color: $error_color;
    border-color: $error_color;
  }
}
</style>