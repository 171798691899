<template>
  <page-title>{{pageTitle}}</page-title>
  <div class="container">
    <Toolbar class="mb-3 card" v-if="showControls">
      <template #start>
        <Button label="Новая смета" icon="pi pi-plus" class="mr-2" @click="showNewEstimateDialog" />
      </template>

      <template #end>
        <!-- <Button icon="pi pi-search" class="mr-2" />
            <Button icon="pi pi-calendar" class="p-button-success mr-2" />
            <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>
    <div class="card">
      <DataTable
        :value="estimates"
        v-model:filters="filters" 
        filterDisplay="row" 
        :loading="loading" 
        class="p-datatable-sm"
        responsiveLayout="scroll"
        :globalFilterFields="['name','estimate_type_id','user_id','article']"
        :paginator="true"
        :rows="10"
        showGridlines 
      >
        <!-- <Column field="id" header="ID" v-if="showControls"></Column> -->
        <Column field="name" header="Наименование" style="min-width: 300px">
          <template #body="slotProps">
            <router-link :to="'/estimates/' + slotProps.data.id">{{
              slotProps.data.name
            }}</router-link>
            <!-- <router-link :to="'/view/' + slotProps.data.id" v-else>{{
              slotProps.data.name
            }}</router-link> -->
          </template>
          <template #filter="{filterModel,filterCallback}">
            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter p-inputtext-sm" :placeholder="`Искать по названию`" v-tooltip.top.focus="'Введите название'"/>
          </template>
        </Column>
        <Column field="estimate_type_id" header="Шаблон">
          <template #body="slotProps">
            {{ formatTypeName(slotProps.data.estimate_type_id) }}
          </template>
          <template #filter="{filterModel,filterCallback}">
            <MultiSelect v-model="filterModel.value" @change="filterCallback()" :options="allTypes" optionLabel="name" optionValue="id" placeholder="Любой" class="p-column-filter p-inputtext-sm">
                <template #option="slotProps">
                    <div class="p-multiselect-representative-option">
                        <span>{{slotProps.option.name}}</span>
                    </div>
                </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="user_id" header="Ответственный">
          <template #body="slotProps">
            {{ formatUserName(slotProps.data.user_id) }}
          </template>
          <template #filter="{filterModel,filterCallback}">
            <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="allUsers" optionLabel="name" optionValue="id" placeholder="Любой" class="p-column-filter p-dropdown-sm p-inputtext-sm" :showClear="true">
            </Dropdown>
          </template>
        </Column>
        <Column field="article" header="Артикул">
          <template #filter="{filterModel,filterCallback}">
            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter p-inputtext-sm" :placeholder="`Искать по артикулу`" v-tooltip.top.focus="'Введите артикул'"/>
          </template>
        </Column>
        <Column header="Размеры">
          <template #body="slotProps">
            {{ slotProps.data.param_width }}х{{ slotProps.data.param_length }}
          </template>
        </Column>
        <Column field="modified" header="Изменено"></Column>
        <Column field="status" header="Статус" v-if="showControls">
          <template #body="slotProps">
            {{ formatStatus(slotProps.data.status) }}
          </template>
        </Column>
        <Column v-if="showControls" style="width: 100px">
          <template #body="slotProps">
            <Button
              @click="this.$router.push('/estimates/' + slotProps.data.id)"
              icon="pi pi-pencil"
              class="p-button-rounded p-button p-button-outlined mr-2"
            />
            <Button
              @click="confirmDeleteEstimate($event, slotProps.data.id)"
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-outlined"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>

  <Dialog header="Новая смета" v-model:visible="displayEstimateDialog" :style="{ width: '460px' }" >
    <div class="form_field">
        <label for="estimate_name"><span>Наименование сметы</span>
            <InputText type="text" id="estimate_name" class="w-full" v-model="newEstimateData.name" placeholder="Название сметы"/>
        </label>
    </div>
    <div class="form_field">
        <label for="estimate_user_id"><span>Ответственное лицо</span>
            <Dropdown v-model="newEstimateData.user" id="estimate_user_id" :class="this.userData.role == 'estimator' ? 'p-disabled w-full' : 'w-full'" :options="allUsers" optionLabel="name" placeholder="Выберите специалиста">
                <template #value="slotProps">
                  <div v-if="slotProps.value">
                      <span>{{slotProps.value.name}}</span>
                  </div>
                  <span v-else>
                      {{slotProps.placeholder}}
                  </span>
                </template>
                <template #option="slotProps">
                  <div>
                      <div>{{slotProps.option.name}}</div>
                  </div>
                </template>
            </Dropdown>
        </label>
    </div>
    <div class="form_field">
        <label for="estimate_type_id"><span>Шаблон сметы</span>
            <Dropdown v-model="newEstimateData.type" id="estimate_type_id" class="w-full" :options="allTypes" optionLabel="name" placeholder="Выберите шаблон">
                <template #value="slotProps">
                <div v-if="slotProps.value">
                    <span>{{slotProps.value.name}}</span>
                </div>
                <span v-else>
                    {{slotProps.placeholder}}
                </span>
                </template>
                <template #option="slotProps">
                <div>
                    <div>{{slotProps.option.name}}</div>
                </div>
                </template>
            </Dropdown>
        </label>
    </div>
    <template #footer>
      <Button label="Отмена" icon="pi pi-times" @click="displayEstimateDialog = false" class="p-button-text"/>
      <Button label="Создать" icon="pi pi-check" @click="addEstimate" autofocus />
    </template>
  </Dialog>
</template>

<script>
import EstimateService from "../services/EstimateService";
import UserService from "../services/UserService";
import {FilterMatchMode} from 'primevue/api';

export default {
  props: ['userData'],
  data() {
    return {
      // userData: {
      //   id: 1,
      //   group: "admin",
      //   name: "Александр Солдатов"
      // },
      pageTitle: "Мои сметы",
      estimates: [],
      allUsers: [],
      allTypes: [],
      displayEstimateDialog: false,
      newEstimateData: {
          name: null,
          user: null,
          type: null,
      },
      showControls: null,
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'name': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'estimate_type_id': {value: null, matchMode: FilterMatchMode.IN},
        'user_id': {value: null, matchMode: FilterMatchMode.EQUALS},
        'article': {value: null, matchMode: FilterMatchMode.CONTAINS}
      },
      loading: true,
    };
  },
  EstimateService: null,
  UserService: null,
  created() {
    this.EstimateService = new EstimateService();
    this.UserService = new UserService();
  },
  mounted() {
    let checkUserData = setInterval(() => {
      if (this.userData != null) {
        this.getAllEstimates(this.userData.permissions, this.userData.role, this.userData.id);
        this.getAllUsers();
        this.getAllTypes();
        this.setPageTitle();
        this.checkControlsVisibility();
        clearInterval(checkUserData);
      }
    }, 200);
    // let checkUserData = setInterval(() => {
    //   if (this.userData != null) {
    //     this.getAllEstimates(this.userData.permissions, this.userData.id);
    //     clearInterval(checkUserData);
    //   }
    // }, 200);
  },
  methods: {
    checkControlsVisibility() {
      if (this.userData.role == 'director' || this.userData.role == 'estimator' || this.userData.permissions == 'admin') {
        this.showControls = true;
      } else {
        this.showControls = false;
      }
    },
    getAllEstimates(permissions, role, user_id) {
      const that = this;
      this.EstimateService.getAllEstimates(permissions, role, user_id).then(
        (data) => {
          if (data.status) {
            that.estimates = data.message;
            that.loading = false;
          } else {
            that.$toast.add({
              severity: "error",
              detail: data.message,
              life: 3000,
            });
          }
        }
      );
    },
    getAllUsers() {
      // получение все пользователей из БД
      this.UserService.getAllUsers().then((data) => {
        if (data.status) {
          this.allUsers = data.message;
        } else {
          this.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    },
    getAllTypes() {
      // получение всех шаблонов из БД
      this.EstimateService.getAllTypes().then((data) => {
        if (data.status) {
          this.allTypes = data.message;
        } else {
          this.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    },
    formatUserName(id) {
      for (let user of this.allUsers) {
        if (user.id == id) {
          return user.name;
        }
      }
    },
    formatTypeName(id) {
      for (let type of this.allTypes) {
        if (type.id == id) {
          return type.name;
        }
      }
    },
    formatStatus(value) {
      if (value == 1 || value === "1") {
        return "Доступно";
      } else {
        return "Недоступно";
      }
    },
    confirmDeleteEstimate(event, est_id) {
      // запрос подтверждения удаления сметы
      const that = this;
      this.$confirm.require({
        target: event.currentTarget,
        message: "Удалить эту смету?",
        header: "Подтверждение",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          that.removeEstimate(est_id);
        },
      });
    },
    showNewEstimateDialog() { // открытие окна для создания сметы
        this.displayEstimateDialog = true;
        this.newEstimateData.user = {id: this.userData.id, name: this.userData.firstname + ' ' + this.userData.lastname};
    },
    addEstimate() {
        const that = this;
        if (this.newEstimateData.name && this.newEstimateData.user && this.newEstimateData.type) {
            const formData = new FormData();
            formData.append('action', 'createEstimate');
            formData.append('name', this.newEstimateData.name);
            formData.append('user_id', this.newEstimateData.user.id);
            formData.append('type_id', this.newEstimateData.type.id);

            this.EstimateService.createEstimate(formData).then(data => {
                // console.log(data);
                if (data.status) {
                    that.$toast.add({
                        severity: "success",
                        detail: data.message,
                        life: 3000,
                    });
                    that.displayEstimateDialog = false;
                    setTimeout(function() {
                        that.$router.push('/estimates/' + data.id);
                    }, 1000);
                } else {
                    that.$toast.add({
                        severity: "error",
                        detail: data.message,
                        life: 3000,
                    });
                }
            });
        } else {
            this.$toast.add({
                severity: "warn",
                detail: 'Необходимо заполнить все поля',
                life: 3000,
            });
        }
    },
    removeEstimate(id) {
      // удаление сметы из БД
      const that = this;
      this.EstimateService.removeEstimate(id).then((data) => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 3000,
          });
          that.getAllEstimates(that.userData.group, this.userData.role, that.userData.id);
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    },
    setPageTitle() {
      if (this.userData.role == "estimator") {
        this.pageTitle = "Мои сметы";
      } else {
        this.pageTitle = "Список всех смет";
      }
    }
  },
};
</script>

<style lang="scss" >
.p-toolbar {
  border: none;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  font-size: 14px;
}
.p-datatable {
  .p-button.p-button-icon-only {
    height: 28px !important;
    width: 28px !important;
  }
    .pi {
      font-size: 12px !important;
    }
    .p-multiselect-label {
font-size: 0.875rem;
    padding: 0.4375rem 0.4375rem;
    }
}
</style>