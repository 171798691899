<template>
  <page-title>{{showControls == true ? 'Редактирование сметы' : 'Готовая смета'}}</page-title>
  <div class="container est-edit">
    <div class="est-edit_header">
      <div class="est-edit_header_controls" v-if="showControls">
        <div class="card flex flex-wrap">
          <div class="col-5 column">
            <div class="form_field">
              <label for="estimate_name">
                <span>Наименование сметы</span>
                <InputText
                  type="text"
                  id="estimate_name"
                  class="w-full"
                  v-model="estimateName"
                  placeholder="Название сметы"
                />
              </label>
            </div>
            <div class="form_field">
              <label for="estimate_author">
                <span>Ответственное лицо</span>
                <Dropdown
                  v-model="estimateAuthor"
                  id="estimate_author"
                  class="w-full"
                  :options="allUsers"
                  optionLabel="name"
                  placeholder="Выберите специалиста"
                >
                  <template #value="slotProps">
                    <div v-if="slotProps.value">
                      <span>{{slotProps.value.name}}</span>
                    </div>
                    <span v-else>{{slotProps.placeholder}}</span>
                  </template>
                  <template #option="slotProps">
                    <div>
                      <div>{{slotProps.option.name}}</div>
                    </div>
                  </template>
                </Dropdown>
              </label>
            </div>
          </div>
          <div class="col-4 column flex flex-wrap">
            <div class="col-12 p-0">
              <div class="form_field">
                <label for="estimate_type">
                  <span>Шаблон сметы</span>
                  <Dropdown
                    v-model="estimateType"
                    class="w-full"
                    id="estimate_type"
                    :options="allTypes"
                    optionLabel="name"
                    placeholder="Выберите шаблон"
                  >
                    <template #value="slotProps">
                      <div v-if="slotProps.value">
                        <span>{{slotProps.value.name}}</span>
                      </div>
                      <span v-else>{{slotProps.placeholder}}</span>
                    </template>
                    <template #option="slotProps">
                      <div>
                        <div>{{slotProps.option.name}}</div>
                      </div>
                    </template>
                  </Dropdown>
                </label>
              </div>
            </div>
            <div class="col-6 p-0 pr-2">
              <div class="form_field">
                <label class="form_field_sizes">
                  <span>Размеры</span>
                  <div class="inputs_wrapper">
                    <InputNumber
                      id="estimate_param_size_w"
                      v-model="estimate_param_size_w"
                      placeholder="Ш"
                    />
                    <span>х</span>
                    <InputNumber
                      id="estimate_param_size_l"
                      v-model="estimate_param_size_l"
                      placeholder="Д"
                    />
                  </div>
                </label>
              </div>
            </div>
            <div class="col-6 p-0 pl-2">
              <div class="form_field">
                <label for="estimate_param_article">
                  <span>Артикул</span>
                  <InputText
                    type="text"
                    id="estimate_param_article"
                    class="w-full"
                    v-model="estimate_param_article"
                  />
                </label>
              </div>
            </div>
          </div>
          <div class="col-3 column flex flex-wrap">
            <div class="form_field">
              <label for="estimate_status">
                <span>Доступно для МОП</span>
                <InputSwitch
                  v-model="estimateStatus"
                  trueValue="1"
                  falseValue="0"
                  id="estimate_status"
                />
              </label>
            </div>
            <div class="form_field">
              <label for="comparison_mode">
                <span>Режим проверки</span>
                <InputSwitch
                  v-model="comparisonMode"
                  id="comparison_mode"
                  @change="compareTemplateWithEstimate"
                />
              </label>
            </div>
            <div class="form_field w-full">
              <label>
                <span>&nbsp;</span>
                <Button label="Сохранить" class="w-full" @click="saveEstimate" />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div :class="showControls == true ? 'est-edit_header_info pl-6' : 'est-edit_header_info'">
        <p class="title">{{estimateName}}</p>
        <p class="parameter">
          Тип дома:
          <span>{{estimate_param_type}}</span>
        </p>
        <p class="parameter">
          Размеры:
          <span>{{estimate_param_size_w}}х{{estimate_param_size_l}}</span>
        </p>
        <p class="parameter">
          Площадь:
          <span>{{estimate_square}} м<sup>2</sup></span>
        </p>
        <p class="parameter">
          Артикул:
          <span>{{estimate_param_article}}</span>
        </p>
        <p class="parameter">
          Ответственный:
          <span>{{estimate_param_author}}</span>
        </p>
        <p class="parameter">
          Цена за м<sup>2</sup>:
          <span>{{formatCurrency(estimate_square_price)}}</span>
        </p>
        <p class="total">
          Стоимость:
          <span>{{formatCurrency(estimate_total_price)}}</span>
        </p>
        <!-- <Button label="Скачать PDF" @click="getPDF" class="mr-2 p-disabled"/> -->
        <Button label="Скачать XLSX" @click="getXLSX" />
      </div>
    </div>

    <div id="estimate_container" ref="estimate_container">
      <!-- <div class="flex" v-if="comparisonMode">
        <pre class="col-4" style="height: 60vh; overflow: scroll; font-family: monospace;">{{templateData}}</pre>
        <pre class="col-4" style="height: 60vh; overflow: scroll; font-family: monospace;">{{estimateData}}</pre>
        <pre class="col-4" style="height: 60vh; overflow: scroll; font-family: monospace;">{{comparedData}}</pre>
      </div> -->

      
      <div v-if="!comparisonMode">
        <div class="card" v-for="step in estimateData" :key="step">
          <!-- <pre style="height: 60vh; overflow: scroll; font-family: monospace;">{{estimateData}}</pre> -->
          <Fieldset :legend="step.s_name" :toggleable="false" class="mb-2 pt-0">
            <template #legend>
              <div class="legend_header_wrap">
                {{step.s_name}}
                <Button
                  v-if="showControls"
                  @click="confirmDeleteStep($event, step.s_id)"
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger p-button-text ml-2"
                />
              </div>
            </template>
            <div
              :id="'estimate_step_' + step.s_id"
              :ref="'estimate_step_' + step.s_id"
              v-for="workflow in step.workflows"
              :key="workflow"
            >
              <Fieldset
                :legend="workflow.w_name"
                :toggleable="false"
                class="mb-4"
                v-if="workflow.disabled"
              >
                <template #legend>
                  <div class="legend_header_wrap">
                    {{workflow.w_name}}
                    <Button
                      v-if="showControls"
                      @click="confirmDeleteWorkflow($event, step.s_id, workflow.w_id)"
                      icon="pi pi-trash"
                      class="p-button-rounded p-button-danger p-button-text ml-2"
                    />
                  </div>
                </template>
                <DataTable
                  :value="workflow.materials"
                  class="p-datatable-sm mb-0"
                  responsiveLayout="scroll"
                  showGridlines
                >
                  <template #header>Материалы</template>
                  <template #empty>Материалы не указаны</template>
                  <Column field="name" header="Наименование" class="tmp_estimate_col_name"></Column>
                  <Column field="vendor" header="Поставщик" class="tmp_estimate_col_vendor"></Column>
                  <Column field="article" header="Артикул" class="tmp_estimate_col_article"></Column>
                  <Column field="type" class="tmp_estimate_col_type">
                    <template #body="slotProps">
                      <i :class="formatType(slotProps.data.type)"></i>
                    </template>
                  </Column>
                  <Column
                    field="price"
                    header="Цена"
                    class="tmp_estimate_col_price text-right"
                    v-if="userData.permissions == 'admin' || userData.role == 'director'"
                  >
                    <template #body="slotProps">
                      <span>{{formatCurrency(slotProps.data.price)}}</span>
                    </template>
                  </Column>
                  <Column
                    field="markup"
                    header="Наценка"
                    class="tmp_estimate_col_markup"
                    v-if="userData.permissions == 'admin' || userData.role == 'director'"
                  >
                    <template #body="slotProps">
                      <span>{{slotProps.data.markup}}%</span>
                    </template>
                  </Column>
                  <Column field="count" header="Количество" class="tmp_estimate_col_count">
                    <template #body="slotProps">
                      <InputNumber
                        v-if="showControls"
                        id="horizontal"
                        v-model="slotProps.data.count"
                        showButtons
                        buttonLayout="horizontal"
                        :step="1"
                        :min="1"
                        :max="9999999999"
                        decrementButtonClass="p-button-danger"
                        incrementButtonClass="p-button-success"
                        incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus"
                      />
                      <span v-else>{{slotProps.data.count}}</span>
                    </template>
                  </Column>
                  <Column field="unit" header="Ед.изм." class="tmp_estimate_col_unit"></Column>
                  <Column header="Стоимость" class="tmp_estimate_col_price text-right">
                    <template #body="slotProps">
                      <span>{{formatCurrency(calcItemCost(slotProps.data.price, slotProps.data.count, slotProps.data.markup))}}</span>
                    </template>
                  </Column>
                  <Column class="tmp_estimate_col_controls" v-if="showControls">
                    <template #body="slotProps">
                      <Button
                        @click="confirmDeleteMaterial($event, step.s_id, workflow.w_id, slotProps.data.id)"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger p-button-outlined"
                      />
                    </template>
                  </Column>
                  <template #footer>
                    <div style="display: flex;">
                      <p class="tmp_estimate_col_name m-0 text-right">Итого материалы:</p>
                      <p
                        class="tmp_estimate_col_price m-0 text-right"
                      >{{formatCurrency(calcWorkflowObjects(workflow.materials))}}</p>
                      <p class="tmp_estimate_col_controls m-0"></p>
                    </div>
                  </template>
                </DataTable>
                <DataTable
                  :value="workflow.works"
                  class="p-datatable-sm mb-0"
                  responsiveLayout="scroll"
                  showGridlines
                >
                  <template #header>Работы</template>
                  <template #empty>Работы не указаны</template>
                  <Column field="name" header="Наименование" class="tmp_estimate_col_name"></Column>
                  <Column field="article" header="Артикул" class="tmp_estimate_col_article"></Column>
                  <Column field="type" class="tmp_estimate_col_type">
                    <template #body="slotProps">
                      <i :class="formatType(slotProps.data.type)"></i>
                    </template>
                  </Column>
                  <Column
                    field="price"
                    header="Цена"
                    class="tmp_estimate_col_price text-right"
                    v-if="userData.permissions == 'admin' || userData.role == 'director'"
                  >
                    <template #body="slotProps">
                      <span>{{formatCurrency(slotProps.data.price)}}</span>
                    </template>
                  </Column>
                  <Column
                    header="&mdash;"
                    class="tmp_estimate_col_markup"
                    v-if="userData.permissions == 'admin' || userData.role == 'director'"
                  ></Column>
                  <Column field="count" header="Количество" class="tmp_estimate_col_count">
                    <template #body="slotProps">
                      <InputNumber
                        v-if="showControls"
                        id="horizontal"
                        v-model="slotProps.data.count"
                        showButtons
                        buttonLayout="horizontal"
                        :step="1"
                        :min="1"
                        :max="9999999999"
                        decrementButtonClass="p-button-danger"
                        incrementButtonClass="p-button-success"
                        incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus"
                      />
                      <span v-else>{{slotProps.data.count}}</span>
                    </template>
                  </Column>
                  <Column field="unit" header="Ед.изм." class="tmp_estimate_col_unit"></Column>
                  <Column header="Стоимость" class="tmp_estimate_col_price text-right">
                    <template #body="slotProps">
                      <span>{{formatCurrency(calcItemCost(slotProps.data.price, slotProps.data.count, slotProps.data.markup))}}</span>
                    </template>
                  </Column>
                  <Column class="tmp_estimate_col_controls" v-if="showControls">
                    <template #body="slotProps">
                      <Button
                        @click="confirmDeleteWorks($event, step.s_id, workflow.w_id, slotProps.data.id)"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger p-button-outlined"
                      />
                    </template>
                  </Column>
                  <template #footer>
                    <div style="display: flex;">
                      <p class="tmp_estimate_col_name m-0 text-right">Итого работы:</p>
                      <p
                        class="tmp_estimate_col_price m-0 text-right"
                      >{{formatCurrency(calcWorkflowObjects(workflow.works))}}</p>
                      <p class="tmp_estimate_col_controls m-0"></p>
                    </div>
                  </template>
                </DataTable>
                <DataTable
                  :value="workflow.expenses"
                  class="p-datatable-sm mb-0"
                  responsiveLayout="scroll"
                  v-if="workflow.expenses.length > 0"
                  showGridlines
                >
                  <template #header>Дополнительные расходы</template>
                  <template #empty>Дополнительные расходы не указаны</template>
                  <Column field="name" header="Наименование" class="tmp_estimate_col_name"></Column>
                  <Column field="type" class="tmp_estimate_col_type">
                    <template #body="slotProps">
                      <i :class="formatType(slotProps.data.type)"></i>
                    </template>
                  </Column>
                  <Column
                    header="&mdash;"
                    class="tmp_estimate_col_price text-right"
                    v-if="userData.permissions == 'admin' || userData.role == 'director'"
                  ></Column>
                  <Column field="markup" header="Наценка" class="tmp_estimate_col_markup">
                    <template #body="slotProps">
                      <div>{{slotProps.data.markup}}%</div>
                    </template>
                  </Column>
                  <Column header="&mdash;" class="tmp_estimate_col_count"></Column>
                  <Column header="&mdash;" class="tmp_estimate_col_unit"></Column>
                  <Column header="Стоимость" class="tmp_estimate_col_price text-right">
                    <template #body="slotProps">
                      <span v-if="slotProps.data.type == 'material'">{{formatCurrency(calcWorkflowMaterialsWithExpenses(workflow.materials, workflow.expenses))}}</span>
                      <span v-if="slotProps.data.type == 'works'">{{formatCurrency(calcWorkflowWorksWithExpenses(workflow.works, workflow.expenses))}}</span>
                    </template>
                  </Column>
                  <Column class="tmp_estimate_col_controls" v-if="showControls">
                    <template #body="slotProps">
                      <Button
                        @click="confirmDeleteExpense($event, step.s_id, workflow.w_id, slotProps.data.id)"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger p-button-outlined"
                      />
                    </template>
                  </Column>
                  <template #footer>
                    <div style="display: flex;">
                      <p class="tmp_estimate_col_name m-0 text-right">Итого с расходами:</p>
                      <p
                        class="tmp_estimate_col_price m-0 text-right"
                      >{{formatCurrency(calcWorkflowMaterialsWithExpenses(workflow.materials, workflow.expenses) + calcWorkflowWorksWithExpenses(workflow.works, workflow.expenses))}}</p>
                      <p class="tmp_estimate_col_controls m-0"></p>
                    </div>
                  </template>
                </DataTable>

                <Button
                  v-if="showControls"
                  label="Добавить материалы"
                  icon="pi pi-plus"
                  class="p-button-secondary mt-4 mr-2"
                  @click="openAddMaterialDalog(step.s_id, workflow.w_id, workflow.materials)"
                />
                <Button
                  v-if="showControls"
                  label="Добавить работы"
                  icon="pi pi-plus"
                  class="p-button-help mt-4"
                  @click="openAddWorksDalog(step.s_id, workflow.w_id, workflow.works)"
                />
                <Button
                  v-if="showControls"
                  label="Добавить расходы"
                  icon="pi pi-plus"
                  :class="workflow.expenses.length > 1 ? 'p-button-info mt-4 ml-3 p-disabled' : 'p-button-info mt-4 ml-3'"
                  @click="openAddExpensesDalog(step.s_id, workflow.w_id, workflow.expenses)"
                />
              </Fieldset>
            </div>

            <SplitButton
              label="Добавить раздел"
              :model="step.workflows"
              class="mt-2"
              v-if="showControls"
            ></SplitButton>
          </Fieldset>
        </div>
      </div>
      <div v-else>
        <div class="card" v-for="step in comparedData" :key="step">
          <Fieldset :legend="step.s_name" :toggleable="false" class="mb-2 pt-0">
            <template #legend>
              <div :class="step.class != null ? workflowClassComparison(step.class) + ' legend_header_wrap' : 'legend_header_wrap'">
                {{step.s_name}}
              </div>
            </template>
            <div
              class="mb-4"
              v-for="workflow in step.workflows"
              :key="workflow"
            >
              <Fieldset
                :legend="workflow.w_name"
                :toggleable="false"
              >
                <template #legend>
                  <div :class="workflow.class != null ? workflowClassComparison(workflow.class) + ' legend_header_wrap' : 'legend_header_wrap'">
                    {{workflow.w_name}}
                  </div>
                </template>
                <DataTable
                  :value="workflow.materials"
                  class="p-datatable-sm mb-0"
                  responsiveLayout="scroll"
                  showGridlines
                  :rowClass="rowClassComparison"
                >
                  <template #header>Материалы</template>
                  <template #empty>Материалы не указаны</template>
                  <Column field="name" header="Наименование" class="tmp_estimate_col_name"></Column>
                  <Column field="article" header="Артикул" class="tmp_estimate_col_article"></Column>
                  <Column field="type" class="tmp_estimate_col_type">
                    <template #body="slotProps">
                      <i :class="formatType(slotProps.data.type)"></i>
                    </template>
                  </Column>
                </DataTable>
                <DataTable
                  :value="workflow.works"
                  class="p-datatable-sm mb-0"
                  responsiveLayout="scroll"
                  showGridlines
                  :rowClass="rowClassComparison"
                >
                  <template #header>Работы</template>
                  <template #empty>Работы не указаны</template>
                  <Column field="name" header="Наименование" class="tmp_estimate_col_name"></Column>
                  <Column field="article" header="Артикул" class="tmp_estimate_col_article"></Column>
                  <Column field="type" class="tmp_estimate_col_type">
                    <template #body="slotProps">
                      <i :class="formatType(slotProps.data.type)"></i>
                    </template>
                  </Column>
                </DataTable>
                <DataTable
                  :value="workflow.expenses"
                  class="p-datatable-sm mb-0"
                  responsiveLayout="scroll"
                  v-if="workflow.expenses.length > 0"
                  showGridlines
                  :rowClass="rowClassComparison"
                >
                  <template #header>Дополнительные расходы</template>
                  <template #empty>Дополнительные расходы не указаны</template>
                  <Column field="name" header="Наименование" class="tmp_estimate_col_name"></Column>
                  <Column field="type" class="tmp_estimate_col_type">
                    <template #body="slotProps">
                      <i :class="formatType(slotProps.data.type)"></i>
                    </template>
                  </Column>
                </DataTable>
              </Fieldset>
            </div>

          </Fieldset>
        </div>
      </div>

      <SplitButton
        label="Добавить этап"
        :model="estimateTypeSteps"
        class="m-4 mr-2"
        v-if="showControls && !comparisonMode"
      ></SplitButton>

      <Button
        label="Сохранить смету"
        icon="pi pi-check"
        @click="saveEstimate"
        class="m-4 ml-2"
        v-if="showControls && !comparisonMode"
      />
    </div>
  </div>

  <Dialog
    v-if="showControls"
    header="База материалов"
    v-model:visible="addMaterialDialogVisible"
    :maximizable="true"
    :style="{'max-width': '95vw', 'width': '1600px'}"
  >
    <DataTable
      :value="preparedMaterials"
      class="p-datatable-sm"
      responsiveLayout="scroll"
      :paginator="true"
      :rows="30"
      v-model:selection="selectedMaterials"
      data-key="id"
      :rowClass="rowClass"
      selectionMode="multiple"
      :metaKeySelection="false"
      v-model:filters="filters"
      filterDisplay="row"
      showGridlines
      :globalFilterFields="['name','article','vendor','vendor_article']"
      :lazy="true"
      :totalRecords="totalMaterialsRecords"
      @page="onPage($event, 'material')"
      @sort="onSort($event, 'material')"
      @filter="onFilter($event, 'material')" 
    >
      <template #empty>Материалы не найдены</template>
      <!-- <Column selectionMode="multiple" headerStyle="width: 3em">
      </Column>-->
      <Column field="type" class="tmp_estimate_col_type">
        <template #body="slotProps">
          <i :class="formatType(slotProps.data.type)"></i>
        </template>
      </Column>
      <Column
        field="name"
        header="Наименование"
        class="tmp_estimate_col_name"
        :showFilterMatchModes="false"
        :showFilterOperator="false"
        :showFilterMenu="false"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter p-inputtext-sm"
            :placeholder="`Поиск`"
            v-tooltip.top.focus="'Введите название'"
          />
        </template>
      </Column>
      <Column
        field="article"
        header="Артикул"
        class="tmp_estimate_col_article"
        :showFilterMatchModes="false"
        :showFilterOperator="false"
        :showFilterMenu="false"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter p-inputtext-sm"
            :placeholder="`Поиск`"
            v-tooltip.top.focus="'Введите артикул'"
          />
        </template>
      </Column>
      <Column field="unit" header="Ед.изм." class="tmp_estimate_col_unit"></Column>
      <Column
        field="price"
        header="Цена"
        v-if="userData.permissions == 'admin' || userData.role == 'director'"
      >
        <template #body="slotProps">
          <span>{{formatCurrency(slotProps.data.price)}}</span>
        </template>
      </Column>
      <Column
        field="markup"
        header="Наценка"
        v-if="userData.permissions == 'admin' || userData.role == 'director'"
        class="tmp_estimate_col_markup"
      >
        <template #body="slotProps">
          <span>{{slotProps.data.markup}}%</span>
        </template>
      </Column>
      <Column header="Стоимость" class="tmp_estimate_col_price">
        <template #body="slotProps">
          <span>{{formatCurrency(calcItemCost(slotProps.data.price, 1, slotProps.data.markup))}}</span>
        </template>
      </Column>
      <!-- <Column field="modified" header="Дата изменения" sortable></Column> -->
      <Column
        field="vendor"
        header="Поставщик"
        class="tmp_estimate_col_vendor"
        :showFilterMatchModes="false"
        :showFilterOperator="false"
        :showFilterMenu="false"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter p-inputtext-sm"
            :placeholder="`Поиск`"
            v-tooltip.top.focus="'Введите название поставщика'"
          />
        </template>
      </Column>
      <Column
        field="vendor_article"
        header="Код"
        class="tmp_estimate_col_vendor_article"
        :showFilterMatchModes="false"
        :showFilterOperator="false"
        :showFilterMenu="false"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter p-inputtext-sm"
            :placeholder="`Поиск`"
            v-tooltip.top.focus="'Введите артикул поставщика'"
          />
        </template>
      </Column>
    </DataTable>
    <template #footer>
      <Button
        label="Отмена"
        icon="pi pi-times"
        @click="addMaterialDialogVisible = false"
        class="p-button-text"
      />
      <Button label="Добавить выбранное" icon="pi pi-check" @click="addMaterial" />
    </template>
  </Dialog>

  <Dialog
    v-if="showControls"
    header="База работ"
    v-model:visible="addWorksDialogVisible"
    :maximizable="true"
    :style="{'max-width': '95vw', 'width': '1600px'}"
  >
    <DataTable
      :value="preparedWorks"
      class="p-datatable-sm"
      responsiveLayout="scroll"
      :paginator="true"
      :rows="30"
      v-model:selection="selectedWorks"
      data-key="id"
      :rowClass="rowClass"
      selectionMode="multiple"
      :metaKeySelection="false"
      v-model:filters="filters"
      filterDisplay="row"
      showGridlines
      :globalFilterFields="['name','article']"

      :lazy="true"
      :totalRecords="totalWorksRecords"
      @page="onPage($event, 'works')"
      @sort="onSort($event, 'works')"
      @filter="onFilter($event, 'works')" 
    >
      <template #empty>Работы не найдены</template>
      <Column field="type" class="tmp_estimate_col_type">
        <template #body="slotProps">
          <i :class="formatType(slotProps.data.type)"></i>
        </template>
      </Column>
      <Column
        field="name"
        header="Наименование"
        class="tmp_estimate_col_name"
        :showFilterMatchModes="false"
        :showFilterOperator="false"
        :showFilterMenu="false"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter p-inputtext-sm"
            :placeholder="`Поиск`"
            v-tooltip.top.focus="'Введите название'"
          />
        </template>
      </Column>
      <Column
        field="article"
        header="Артикул"
        class="tmp_estimate_col_article"
        :showFilterMatchModes="false"
        :showFilterOperator="false"
        :showFilterMenu="false"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter p-inputtext-sm"
            :placeholder="`Поиск`"
            v-tooltip.top.focus="'Введите артикул'"
          />
        </template>
      </Column>
      <Column field="unit" header="Ед.изм." class="tmp_estimate_col_unit"></Column>
      <Column
        field="markup"
        header="Наценка"
        v-if="userData.permissions == 'admin' || userData.role == 'director'"
        class="tmp_estimate_col_markup"
      >
        <template #body="slotProps">
          <span>{{slotProps.data.markup}}%</span>
        </template>
      </Column>
    </DataTable>
    <template #footer>
      <Button
        label="Отмена"
        icon="pi pi-times"
        @click="addWorksDialogVisible = false"
        class="p-button-text"
      />
      <Button label="Добавить выбранное" icon="pi pi-check" @click="addWorks" />
    </template>
  </Dialog>

  <Dialog
    v-if="showControls"
    header="База расходов"
    v-model:visible="addExpensesDialogVisible"
    :maximizable="true"
    :style="{'max-width': '95vw', 'width': '800px'}"
  >
    <DataTable
      :value="preparedExpenses"
      class="p-datatable-sm"
      responsiveLayout="scroll"
      :paginator="true"
      :rows="30"
      v-model:selection="selectedExpenses"
      data-key="id"
      :rowClass="rowClass"
      selectionMode="multiple"
      :metaKeySelection="false"
      v-model:filters="filters"
      filterDisplay="row"
      showGridlines
      :globalFilterFields="['name']"
    >
      <template #empty>Расходы не найдены</template>
      <Column field="type" class="tmp_estimate_col_type">
        <template #body="slotProps">
          <i :class="formatType(slotProps.data.type)"></i>
        </template>
      </Column>
      <Column
        field="name"
        header="Наименование"
        class="tmp_estimate_col_name"
        :showFilterMatchModes="false"
        :showFilterOperator="false"
        :showFilterMenu="false"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter p-inputtext-sm"
            :placeholder="`Поиск`"
            v-tooltip.top.focus="'Введите название'"
          />
        </template>
      </Column>
      <Column
        field="markup"
        header="Наценка"
        class="tmp_estimate_col_markup"
      >
        <template #body="slotProps">
          <span>{{slotProps.data.markup}}%</span>
        </template>
      </Column>
    </DataTable>
    <template #footer>
      <Button
        label="Отмена"
        icon="pi pi-times"
        @click="addExpensesDialogVisible = false"
        class="p-button-text"
      />
      <Button label="Добавить выбранное" icon="pi pi-check" @click="addExpenses" />
    </template>
  </Dialog>

</template>

<script>
import EstimateService from "../services/EstimateService";
import UserService from "../services/UserService";
import FilesService from "../services/FilesService";
import { FilterMatchMode } from "primevue/api";

export default {
  props: ["userData"],
  data() {
    return {
      estimateID: this.$route.params.id,
      estimateName: null,
      estimateAuthor: { id: null, name: null },
      estimateType: { id: null, name: null },
      estimateStatus: 0,

      estimate_param_size_w: null,
      estimate_param_size_l: null,
      estimate_param_article: null,

      showControls: null,

      estimateTypeSteps: [],
      estimateData: [],
      templateData: [],
      comparedData: [],
      addMaterialDialogVisible: false,
      addWorksDialogVisible: false,
      addExpensesDialogVisible: false,
      addMaterialData: null,
      addWorksData: null,
      addExpensesData: null,
      allUsers: [],
      allTypes: [],
      allObjects: [],
      allMaterials: [],
      allWorks: [],
      allExpenses: [],
      selectedExpensesStep: null,
      selectedExpensesWorkflow: null,
      selectedMaterials: null,
      selectedWorks: null,
      selectedExpenses: null,

      filters: {
        //'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        article: { value: null, matchMode: FilterMatchMode.CONTAINS },
        vendor: { value: null, matchMode: FilterMatchMode.CONTAINS },
        vendor_article: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },

      comparisonMode: false,

      totalRecords: 0,
      totalMaterialsRecords: 0,
      totalWorksRecords: 0,
      lazyParams: {},
    };
  },
  EstimateService: null,
  UserService: null,
  FilesService: null,
  created() {
    this.EstimateService = new EstimateService();
    this.UserService = new UserService();
    this.FilesService = new FilesService();
  },
  mounted() {
    let checkTypeID = setInterval(() => {
      if (this.estimateType.id != null) {
        this.getEstimateSteps(this.estimateType.id);
        this.getTemplateData(this.estimateType.id);
        clearInterval(checkTypeID);
      }
    }, 200);
    let checkUserData = setInterval(() => {
      if (this.userData != null) {
        this.getAllUsers();
        this.getAllTypes();
        this.getAllObjects();
        this.getAllMaterials();
        this.getAllWorks();
        this.getAllExpenses();
        this.getEstimateData(this.estimateID);
        this.checkControlsVisibility();
        clearInterval(checkUserData);
      }
    }, 200);
    this.lazyParams = {
      first: 0,
      rows: 30,
      sortField: null,
      sortOrder: null,
      filters: this.filters
    };
  },
  computed: {
    estimate_param_author() {
      // получение имени ответственного
      return this.estimateAuthor.name;
    },
    estimate_param_type() {
      // получение название шаблона сметы
      return this.estimateType.name;
    },
    preparedMaterials() {
      // исключение из списка тех материалов, что уже есть в текущих работах
      const that = this;

      var intersectionMaterials = this.addMaterialData.materials.map(function (
        v
      ) {
        return Object.assign(
          {},
          that.allObjects.find(function (v1) {
            return v1.id === v.id;
          }),
          v
        );
      });

      let output = [];

      this.allMaterials.map((item) => {
        //if (item.type == "material") {
          if (
            intersectionMaterials.find(
              (itemMaterial) => itemMaterial.id == item.id
            )
          ) {
            item.class = "p-disabled";
          } else {
            item.class = "";
          }
          output.push(item);
        //}
      });

      return output;
    },
    preparedWorks() {
      // исключение из списка тех работ, что уже есть в текущем процессе
      const that = this;

      var intersectionWorks = this.addWorksData.works.map(function (
        v
      ) {
        return Object.assign(
          {},
          that.allObjects.find(function (v1) {
            return v1.id === v.id;
          }),
          v
        );
      });

      let output = [];

      this.allWorks.map((item) => {

          if (
            intersectionWorks.find(
              (itemWorks) => itemWorks.id == item.id
            )
          ) {
            item.class = "p-disabled";
          } else {
            item.class = "";
          }
          output.push(item);
        
      });

      return output;
    },
    preparedExpenses() {
      // исключение из списка тех расходов, что уже есть в текущих работах. Можно добавить только по одному расходу на работы и материалы.
      const that = this;

      var intersectionExpenses = this.addExpensesData.expenses.map(function (v) {
        return Object.assign(
          {},
          that.allExpenses.find(function (v1) {
            return v1.id === v.id;
          }),
          v
        );
      });

      let output = [];

        let lockMaterials = false;
        let lockWorks = false;
        if (this.addExpensesData.expenses.find((itemData) => itemData.type == 'material')) {
            lockMaterials = true;
        }
        if (this.addExpensesData.expenses.find((itemData) => itemData.type == 'works')) {
            lockWorks = true;
        }

      this.allExpenses.map((item) => {
        if (intersectionExpenses.find((itemExpense) => itemExpense.id == item.id)) {
            item.class = "p-disabled";
        } else {
            if ((item.type == 'material' && lockMaterials) || (item.type == 'works' && lockWorks)) {
                item.class = "p-disabled";
            } else {
                item.class = "";
            }
        }
        output.push(item);
      });

      return output;
    },
    estimate_total_price() {
      // расчёт общей стоимости сметы
      let output_price = 0;

      for (let step of this.estimateData) {
        let sPrice = 0;
        for (let workflow of step.workflows) {
          let wmPrice = this.calcWorkflowMaterialsWithExpenses(
            workflow.materials,
            workflow.expenses
          );
          let wwPrice = this.calcWorkflowWorksWithExpenses(
            workflow.works,
            workflow.expenses
          );
          sPrice = sPrice + wmPrice + wwPrice;
        }
        output_price = output_price + sPrice;
      }

      return output_price;
    },
    estimate_square() {
      if (this.estimate_param_size_w && this.estimate_param_size_l) {
        let square = Number (this.estimate_param_size_w) * Number (this.estimate_param_size_l);
        return square;
      } else {
        return null;
      }
    },
    estimate_square_price() {
      if (this.estimate_param_size_w && this.estimate_param_size_l && this.estimate_total_price) {
        let square = Number (this.estimate_param_size_w) * Number (this.estimate_param_size_l);
        let sq_price = Number (this.estimate_total_price) / square;
        return sq_price;
      } else {
        return null;
      }
    },
  },
  methods: {
    compareTemplateWithEstimate() {
      let newData = [];

      for (let tpl_step of this.templateData) {
        let n_step = {};
        let est_step = this.estimateData.find(step => step.s_id == tpl_step.s_id);
        if (est_step) {
          
          let n_workflows = [];
          for (let tpl_workflow of tpl_step.workflows) {
            
            let n_workflow = {};
            let est_workflow = est_step.workflows.find(workflow => workflow.w_id == tpl_workflow.w_id && workflow.disabled);
            if (est_workflow) {

              ////////////////////////////////
              // обход материалов в процессе
              let n_materials = [];
              // сравнение с шаблоном
              for (let tpl_material of tpl_workflow.materials) {

                let n_material = {};
                let est_material = est_workflow.materials.find(material => material.id == tpl_material.id);
                if (est_material) {
                  n_material.type = tpl_material.type;
                  n_material.id = tpl_material.id;
                  n_material.article = tpl_material.article;
                  n_material.name = tpl_material.name;
                } else {
                  n_material.type = tpl_material.type;
                  n_material.id = tpl_material.id;
                  n_material.article = tpl_material.article;
                  n_material.name = tpl_material.name;
                  n_material.class = 'missing';
                }
                n_materials.push(n_material);
              }

              // проверка на материалы, добавленные не по шаблону
              for (let additional_material of est_workflow.materials) {
                let n_material = {};
                if (!tpl_workflow.materials.find(material => material.id == additional_material.id)) {
                  n_material.type = additional_material.type;
                  n_material.id = additional_material.id;
                  n_material.article = additional_material.article;
                  n_material.name = additional_material.name;
                  n_material.class = 'added';
                  n_materials.push(n_material);
                }
              }
              ////////////////////////////////

              ////////////////////////////////
              // обход работ в процессе
              let n_works = [];
              // сравнение с шаблоном
              for (let tpl_work of tpl_workflow.works) {

                let n_work = {};
                let est_work = est_workflow.works.find(work => work.id == tpl_work.id);
                if (est_work) {
                  n_work.type = tpl_work.type;
                  n_work.id = tpl_work.id;
                  n_work.article = tpl_work.article;
                  n_work.name = tpl_work.name;
                } else {
                  n_work.type = tpl_work.type;
                  n_work.id = tpl_work.id;
                  n_work.article = tpl_work.article;
                  n_work.name = tpl_work.name;
                  n_work.class = 'missing';
                }
                n_works.push(n_work);
              }

              // проверка на работы, добавленные не по шаблону
              for (let additional_work of est_workflow.works) {
                let n_work = {};
                if (!tpl_workflow.works.find(work => work.id == additional_work.id)) {
                  n_work.type = additional_work.type;
                  n_work.id = additional_work.id;
                  n_work.name = additional_work.name;
                  n_work.class = 'added';
                  n_works.push(n_work);
                }
              }
              ////////////////////////////////

              ////////////////////////////////
              // обход расходов в процессе
              let n_expenses = [];
              // сравнение с шаблоном
              for (let tpl_expense of tpl_workflow.expenses) {

                let n_expense = {};
                let est_expense = est_workflow.expenses.find(expense => expense.id == tpl_expense.id);
                if (est_expense) {
                  n_expense.type = tpl_expense.type;
                  n_expense.id = tpl_expense.id;
                  n_expense.name = tpl_expense.name;
                } else {
                  n_expense.type = tpl_expense.type;
                  n_expense.id = tpl_expense.id;
                  n_expense.name = tpl_expense.name;
                  n_expense.class = 'missing';
                }
                n_expenses.push(n_expense);
              }

              // проверка на расходы, добавленные не по шаблону
              for (let additional_expense of est_workflow.expenses) {
                let n_expense = {};
                if (!tpl_workflow.expenses.find(expense => expense.id == additional_expense.id)) {
                  n_expense.type = additional_expense.type;
                  n_expense.id = additional_expense.id;
                  n_expense.name = additional_expense.name;
                  n_expense.class = 'added';
                  n_expenses.push(n_expense);
                }
              }
              ////////////////////////////////
              

              n_workflow.w_id = tpl_workflow.w_id;
              n_workflow.w_name = tpl_workflow.w_name;
              n_workflow.materials = n_materials;
              n_workflow.works = n_works;
              n_workflow.expenses = n_expenses;
              // n_workflow.class = 'matches';
              n_workflows.push(n_workflow);
            } else {
              n_workflow.w_id = tpl_workflow.w_id;
              n_workflow.w_name = tpl_workflow.w_name;
              n_workflow.materials = [];
              n_workflow.works = [];
              n_workflow.expenses = [];
              n_workflow.class = 'missing';
              n_workflows.push(n_workflow);
            }

          }
          n_step.s_id = tpl_step.s_id;
          n_step.s_name = tpl_step.s_name;
          n_step.workflows = n_workflows;
          // n_step.class = 'matches';
          newData.push(n_step);
        } else {
          n_step.s_id = tpl_step.s_id;
          n_step.s_name = tpl_step.s_name;
          n_step.workflows = [];
          n_step.class = 'missing';
          newData.push(n_step);
        }
      }

      this.comparedData = newData;
    },
    getXLSX() {
      if (this.estimateID) {
        this.FilesService.getXLSX(this.estimateID).then((data) => {
          if (data.status) {
            location.href =
              process.env.VUE_APP_URL + "/jwt/api/" + data.message;
          } else {
            this.$toast.add({
              severity: "error",
              detail: data.message,
              life: 3000,
            });
          }
        });
      }
    },
    rowClass(data) {
      // стилизация строки в таблице материалов
      return data.class === "p-disabled" ? "p-disabled" : null;
    },
    rowClassComparison(data) {
      // стилизация строки в таблице сравнения сметы
      switch (data.class) {
        case 'missing':
          return 'bg-orange-200';
        case 'added':
          return 'bg-cyan-200';
        default:
          return 'bg-green-200';
      }
    },
    workflowClassComparison(cls) {
      // стилизация процесса в таблице сравнения сметы
      switch (cls) {
        case 'missing':
          return 'bg-orange-200';
        case 'added':
          return 'bg-cyan-200';
        default:
          return 'bg-green-200';
      }
    },
    getEstimateSteps(typeID) {
      // получение списка этапов по ID типа сметы
      const that = this;
      this.EstimateService.getEstimateSteps(typeID).then((data) => {
        if (data.status) {
          data.message.forEach((item) => {
            that.estimateTypeSteps.push({
              s_id: item.id,
              label: item.name,
              command: () => {
                that.addStep(item.id, item.name);
                for (let e of this.estimateTypeSteps) {
                  if (e.s_id == item.id) {
                    e.disabled = true;
                  }
                }
              },
            });
          });
        }
      });
    },
    getEstimateWorkflows(stepID) {
      // получение списка работ по ID этапа
      const that = this;
      this.EstimateService.getEstimateWorkflows(stepID).then((data) => {
        if (data.status) {
          for (let e of that.estimateData) {
            if (e.s_id == stepID) {
              data.message.forEach((item) => {
                e.workflows.push({
                  w_id: item.id,
                  w_name: item.name,
                  sortindex: item.sortindex,
                  disabled: false,
                  materials: [],
                  works: [],
                  expenses: [],
                  label: item.name,
                  command: () => {
                    that.addWorkflow(stepID, item.id);
                    for (let k of e.workflows) {
                      if (k.w_id == item.id) {
                        k.disabled = true;
                      }
                    }
                  },
                });
              });
            }
          }
        }
      });
    },
    getTemplateData(id) {
      this.EstimateService.getTemplateData(id).then((data) => {
        if (data.status) {
          this.templateData = data.message;
        } else {
          this.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    },
    addStep(id, name) {
      // добавление этапа
      const that = this;
      this.EstimateService.getStepData(id).then((data) => {
        if (data.status) {
          that.estimateData.push({
            s_id: id,
            s_name: name,
            workflows: [],
          });
          that.getEstimateWorkflows(id);
        }
      });
    },
    removeStep(id) {
      // удаление этапа
      const that = this;
      let newEstimateData = [];
      this.estimateData.map(function (item) {
        if (item.s_id != id) {
          newEstimateData.push(item);
        } else {
          for (let e of that.estimateTypeSteps) {
            if (e.s_id == item.s_id) {
              e.disabled = false;
            }
          }
        }
      });
      this.estimateData = newEstimateData;
      this.$toast.add({
        severity: "success",
        summary: "Этап удалён",
        life: 3000,
      });
    },
    addWorkflow(s_id, w_id) {
      // добавление работ в этап
      // console.log(s_id, w_id);
      const that = this;
      this.EstimateService.getWorkflowMaterials(w_id).then((data) => {
        // console.log(data);
        if (data.status) {
          for (let e of that.estimateData) {
            if (e.s_id == s_id) {
              for (let k of e.workflows) {
                if (k.w_id == w_id) {
                  k.materials = data.message;
                }
              }
            }
          }
        }
      });

      this.EstimateService.getWorkflowWorks(w_id).then((data) => {
        // console.log(data);
        if (data.status) {
          for (let e of that.estimateData) {
            if (e.s_id == s_id) {
              for (let k of e.workflows) {
                if (k.w_id == w_id) {
                  k.works = data.message;
                }
              }
            }
          }
        }
      });

      this.EstimateService.getWorkflowExpenses(w_id).then((data) => {
        if (data.status) {
          for (let e of that.estimateData) {
            if (e.s_id == s_id) {
              for (let k of e.workflows) {
                if (k.w_id == w_id) {
                  k.expenses = data.message;
                }
              }
            }
          }
        }
      });
    },
    removeWorkflow(s_id, w_id) {
      // удаление работ из этапа
      this.estimateData.map(function (item) {
        if (item.s_id == s_id) {
          for (let e of item.workflows) {
            if (e.w_id == w_id) {
              e.disabled = false;
              e.materials = [];
            }
          }
        }
      });
      this.$toast.add({
        severity: "success",
        summary: "Работы удалены",
        life: 3000,
      });
    },
    getEstimateData(id) {
      // получение из БД данных сметы по ID
      const that = this;
      this.EstimateService.getEstimateData(id).then((data) => {
        if (data.status) {
          this.estimateName = data.message.name;
          this.estimateStatus = data.message.status;
          this.estimate_param_size_w = Number(data.message.param_width);
          this.estimate_param_size_l = Number(data.message.param_length);
          this.estimate_param_article = data.message.article;

          for (let user of this.allUsers) {
            if (user.id == data.message.user_id) {
              that.estimateAuthor = {
                id: data.message.user_id,
                name: user.firstname + " " + user.lastname,
              };
            }
          }

          for (let type of this.allTypes) {
            if (type.id == data.message.estimate_type_id) {
              that.estimateType = {
                id: data.message.estimate_type_id,
                name: type.name,
              };
            }
          }

          this.estimateType.id = data.message.estimate_type_id;
          //this.estimateData = JSON.parse(data.message.data);
          let parsedData = JSON.parse(data.message.data);
          let handledData = [];
          for (let step of parsedData) {
            let tmpStep = {
              s_id: step.s_id,
              s_name: step.s_name,
              workflows: [],
            };
            step.workflows.forEach((item) => {
              let tmpWorkflow = {
                w_id: item.w_id,
                w_name: item.w_name,
                sortindex: item.sortindex,
                disabled: item.disabled,
                materials: item.materials,
                works: item.works,
                expenses: item.expenses,
                label: item.w_name,
                command: () => {
                  that.addWorkflow(step.s_id, item.w_id);
                  for (let k of tmpStep.workflows) {
                    if (k.w_id == item.w_id) {
                      k.disabled = true;
                    }
                  }
                },
              };
              tmpStep.workflows.push(tmpWorkflow);
            });

            for (let e of that.estimateTypeSteps) {
              if (e.s_id == step.s_id) {
                e.disabled = true;
              }
            }

            handledData.push(tmpStep);
          }

          this.estimateData = handledData;
        } else {
          this.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    },
    openAddMaterialDalog(s_id, w_id, materials) {
      // формирование массива для добавления новых материалов в работы
      this.addMaterialData = {
        s_id: s_id,
        w_id: w_id,
        materials: materials,
      };

      this.addMaterialDialogVisible = true;
    },
    openAddWorksDalog(s_id, w_id, works) {
      // формирование массива для добавления новых работ в процесс
      this.addWorksData = {
        s_id: s_id,
        w_id: w_id,
        works: works,
      };

      this.addWorksDialogVisible = true;
    },
    openAddExpensesDalog(s_id, w_id, expenses) {
      // формирование массива для добавления дополнительных расходов в процесс
      this.addExpensesData = {
        s_id: s_id,
        w_id: w_id,
        expenses: expenses,
      };

      this.addExpensesDialogVisible = true;
    },
    addMaterial() {
      // добавление новых материалов в работы
      this.addMaterialData.materials = this.selectedMaterials;
      this.selectedMaterials.map((item) => (item.count = 1));

      for (let e of this.estimateData) {
        if (e.s_id == this.addMaterialData.s_id) {
          for (let k of e.workflows) {
            if (k.w_id == this.addMaterialData.w_id) {
              let new_materials_array = [
                ...k.materials,
                ...this.addMaterialData.materials,
              ];
              k.materials = new_materials_array;
            }
          }
        }
      }

      this.addMaterialDialogVisible = false;
      this.addMaterialData = null;
      this.selectedMaterials = null;
    },
    addWorks() {
      // добавление новых работ в процесс
      this.addWorksData.works = this.selectedWorks;
      this.selectedWorks.map((item) => (item.count = 1));

      for (let e of this.estimateData) {
        if (e.s_id == this.addWorksData.s_id) {
          for (let k of e.workflows) {
            if (k.w_id == this.addWorksData.w_id) {
              let new_works_array = [
                ...k.works,
                ...this.addWorksData.works,
              ];
              k.works = new_works_array;
            }
          }
        }
      }

      this.addWorksDialogVisible = false;
      this.addWorksData = null;
      this.selectedWorks = null;
    },
    removeMaterial(s_id, w_id, m_id) {
      // удаление материала из работ
      let newEstimateData = [];
      for (let step of this.estimateData) {
        if (step.s_id == s_id) {
          let newWorkflowData = [];
          for (let workflow of step.workflows) {
            if (workflow.w_id == w_id) {
              let newMaterials = [];
              for (let material of workflow.materials) {
                if (material.id != m_id) {
                  newMaterials.push(material);
                }
              }
              workflow.materials = newMaterials;
              newWorkflowData.push(workflow);
            } else {
              newWorkflowData.push(workflow);
            }
          }
          step.workflows = newWorkflowData;
          newEstimateData.push(step);
        } else {
          newEstimateData.push(step);
        }
      }

      this.estimateData = newEstimateData;
    },
    removeWorks(s_id, w_id, m_id) {
      // удаление работ из процесса
      let newEstimateData = [];
      for (let step of this.estimateData) {
        if (step.s_id == s_id) {
          let newWorkflowData = [];
          for (let workflow of step.workflows) {
            if (workflow.w_id == w_id) {
              let newWorks = [];
              for (let work of workflow.works) {
                if (work.id != m_id) {
                  newWorks.push(work);
                }
              }
              workflow.works = newWorks;
              newWorkflowData.push(workflow);
            } else {
              newWorkflowData.push(workflow);
            }
          }
          step.workflows = newWorkflowData;
          newEstimateData.push(step);
        } else {
          newEstimateData.push(step);
        }
      }

      this.estimateData = newEstimateData;
    },
    removeExpense(s_id, w_id, e_id) {
      // удаление доп.расходов из работ
      let newEstimateData = [];
      for (let step of this.estimateData) {
        if (step.s_id == s_id) {
          let newWorkflowData = [];
          for (let workflow of step.workflows) {
            if (workflow.w_id == w_id) {
              let newExpenses = [];
              for (let expense of workflow.expenses) {
                if (expense.id != e_id) {
                  newExpenses.push(expense);
                }
              }
              workflow.expenses = newExpenses;
              newWorkflowData.push(workflow);
            } else {
              newWorkflowData.push(workflow);
            }
          }
          step.workflows = newWorkflowData;
          newEstimateData.push(step);
        } else {
          newEstimateData.push(step);
        }
      }
      this.estimateData = newEstimateData;
    },
    addExpenses() {
      // добавление новых расходов в процесс

      // проверяем, чтобы в выбранных расходах было не более, чем по одной позиции на каждый тип расхода
      let addedMaterialsExpenses = this.selectedExpenses.filter(item => item.type == 'material');
      let addedWorksExpenses = this.selectedExpenses.filter(item => item.type == 'works');
      
      if (addedMaterialsExpenses.length > 1 || addedWorksExpenses.length > 1) {
        this.$toast.add({
            severity: "warn",
            detail: "На материалы и работы допускатеся максимум по одному расходу",
            life: 3000,
        });
      } else {
        this.addExpensesData.expenses = this.selectedExpenses;
        // this.selectedExpenses.map((item) => (item.count = 1));

        for (let e of this.estimateData) {
          if (e.s_id == this.addExpensesData.s_id) {
            for (let k of e.workflows) {
              if (k.w_id == this.addExpensesData.w_id) {
                let new_expenses_array = [
                  ...k.expenses,
                  ...this.addExpensesData.expenses,
                ];
                k.expenses = new_expenses_array;
              }
            }
          }
        }

        this.addExpensesDialogVisible = false;
        this.addExpensesData = null;
        this.selectedExpenses = null;
      }
    },
    getAllObjects() {
      // получение всех материалов и работ из БД
      this.EstimateService.getAllObjects(JSON.stringify( this.lazyParams )).then((data) => {
        //console.log(data);
        if (data.status) {
          this.allObjects = data.message;
          this.totalRecords = data.totalRecords;
        }
      });
    },
    getAllWorks() {
      this.lazyParams.filters.type = {value: 'works'};
      this.EstimateService.getAllObjects(JSON.stringify( this.lazyParams )).then((data) => {
        // console.log(data);
        if (data.status) {
          this.allWorks = data.message;
          this.totalWorksRecords = data.totalRecords;
        }
      });
    },
    getAllMaterials() {
      this.lazyParams.filters.type = {value: 'material'};
      this.EstimateService.getAllObjects(JSON.stringify( this.lazyParams )).then((data) => {
        // console.log(data);
        if (data.status) {
          this.allMaterials = data.message;
          this.totalMaterialsRecords = data.totalRecords;
        }
      });
    },
    getAllExpenses() {
      // получение всех доп.расходов из БД
      this.EstimateService.getAllExpenses().then((data) => {
        if (data.status) {
          this.allExpenses = data.message;
        }
      });
    },
    getAllUsers() {
      // получение все пользователей из БД
      this.UserService.getAllUsers().then((data) => {
        if (data.status) {
          this.allUsers = data.message;
        }
      });
    },
    getAllTypes() {
      // получение все пользователей из БД
      this.EstimateService.getAllTypes().then((data) => {
        if (data.status) {
          this.allTypes = data.message;
        }
      });
    },
    calcItemCost(price, count, markup) {
      // расчёт стоимости материала в строке
      let percent_price = (Number(price) / 100) * Number(markup);
      let new_price = Number(percent_price) + Number(price);
      // console.log('Себестоимость: ' + price);
      // console.log('Наценка: ' + percent_price);
      // console.log('Стоимость с наценкой: ' + new_price);
      let cost = Number(new_price) * Number(count);
      return Number(cost.toFixed(2));
    },
    calcWorkflowObjects(objects) {
      // расчёт стоимости всех материалов или работ в процессе
      let total = 0;
      objects.map((item) => {
        let costItem = this.calcItemCost(
          Number(item.price),
          Number(item.count),
          Number(item.markup)
        );
        total = Number(total) + Number(costItem);
      });
      return Number(total.toFixed(2));
    },
    calcWorkflowMaterialsWithExpenses(materials, expenses) {
      // расчёт стоимости материалов вместе с доп.расходами в процессе
      let total = 0;
      materials.map((item) => {
        let costItem;
        if (item.price && item.count && item.markup) {
          costItem = this.calcItemCost(
            Number(item.price),
            Number(item.count),
            Number(item.markup)
          );
        } else {
          costItem = 0;
        }
        total = Number(total) + Number(costItem);
      });

      let expensesPrice;
      let expenseItem = expenses.find(item => item.type == 'material');
      if (expenseItem) {
        expensesPrice = (Number(total) / 100) * Number(expenseItem.markup);
      } else {
        expensesPrice = 0;
      }

      let totalPrice = Number(total) + Number(expensesPrice);
      return Number(totalPrice.toFixed(2));
    },
    calcWorkflowWorksWithExpenses(works, expenses) {
      // расчёт стоимости работ вместе с доп.расходами в процессе
      let total = 0;
      works.map((item) => {
        let costItem;
        if (item.price && item.count && item.markup) {
          costItem = this.calcItemCost(
            Number(item.price),
            Number(item.count),
            Number(item.markup)
          );
        } else {
          costItem = 0;
        }
        total = Number(total) + Number(costItem);
      });

      let expensesPrice;
      let expenseItem = expenses.find(item => item.type == 'works');
      if (expenseItem) {
        expensesPrice = (Number(total) / 100) * Number(expenseItem.markup);
      } else {
        expensesPrice = 0;
      }

      let totalPrice = Number(total) + Number(expensesPrice);
      return Number(totalPrice.toFixed(2));
    },
    formatCurrency(value) {
      value = Number(value);
      return value.toLocaleString("ru-RU", {
        style: "currency",
        currency: "RUB",
      });
    },
    formatType(value) {
      let output = "";
      switch (value) {
        case "material":
          output = "pi pi-th-large text-cyan-600";
          break;
        case "works":
          output = "pi pi-user text-green-600";
          break;
      }
      return output;
    },
    confirmDeleteStep(event, step_id) {
      // запрос подтверждения удаления этапа из сметы
      const that = this;
      this.$confirm.require({
        target: event.currentTarget,
        message: "Удалить этот этап?",
        header: "Подтверждение",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          that.removeStep(step_id);
        },
      });
    },
    confirmDeleteWorkflow(event, step_id, workflow_id) {
      // запрос подтверждения удаления работы из этапа
      const that = this;
      this.$confirm.require({
        target: event.currentTarget,
        message: "Удалить эти работы?",
        header: "Подтверждение",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          that.removeWorkflow(step_id, workflow_id);
        },
      });
    },
    confirmDeleteMaterial(event, step_id, workflow_id, material_id) {
      // запрос подтверждения удаления материала из работ
      const that = this;
      this.$confirm.require({
        target: event.currentTarget,
        message: "Удалить этот материал?",
        header: "Подтверждение",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          that.removeMaterial(step_id, workflow_id, material_id);
        },
      });
    },
    confirmDeleteWorks(event, step_id, workflow_id, works_id) {
      // запрос подтверждения удаления работ из процесса
      const that = this;
      this.$confirm.require({
        target: event.currentTarget,
        message: "Удалить эти работы?",
        header: "Подтверждение",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          that.removeWorks(step_id, workflow_id, works_id);
        },
      });
    },
    confirmDeleteExpense(event, step_id, workflow_id, expense_id) {
      // запрос подтверждения удаления доп.расходов из работ
      const that = this;
      this.$confirm.require({
        target: event.currentTarget,
        message: "Удалить эти расходы?",
        header: "Подтверждение",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          that.removeExpense(step_id, workflow_id, expense_id);
        },
      });
    },
    toggleExpenses(event, s_id, w_id) {
      // отслеживаем клик по кнопке дополнения доп.расходов в работы
      this.$refs.op.toggle(event);
      this.selectedExpensesStep = s_id;
      this.selectedExpensesWorkflow = w_id;
    },
    onExpenseSelect(event) {
      // выбор доп.расхода из списка
      this.$refs.op.hide();
      this.addExpense(
        this.selectedExpensesStep,
        this.selectedExpensesWorkflow,
        event.data
      );
    },
    saveEstimate() {
      const that = this;
      const formData = new FormData();
      formData.append("action", "saveEstimate");
      // formData.append('token', localStorage.pd_token);
      // formData.append('c_user_id', localStorage.starta_id);
      formData.append("est_id", this.estimateID);
      formData.append("est_type_id", this.estimateType.id);
      formData.append("est_author", this.estimateAuthor.id);
      formData.append("est_name", this.estimateName);
      formData.append("est_data", JSON.stringify(this.estimateData));
      formData.append("est_status", this.estimateStatus);
      formData.append("est_param_w", this.estimate_param_size_w);
      formData.append("est_param_l", this.estimate_param_size_l);
      formData.append("est_article", this.estimate_param_article);

      this.EstimateService.saveEstimate(formData).then((data) => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 3000,
          });
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    },
    checkControlsVisibility() {
      if (
        this.userData.role == "director" ||
        this.userData.role == "estimator" ||
        this.userData.permissions == "admin"
      ) {
        this.showControls = true;
      } else {
        this.showControls = false;
      }
    },
    onPage(event, type) {
      this.lazyParams = event;
      if (type == 'works') {
        this.getAllWorks();
      } else {
        this.getAllMaterials();
      }
      
    },
    onSort(event, type) {
      this.lazyParams = event;
      if (type == 'works') {
        this.getAllWorks();
      } else {
        this.getAllMaterials();
      }
    },
    onFilter(event, type) {
      console.log(event);
      this.lazyParams.filters = this.filters;
      this.lazyParams.first = 0;
      if (type == 'works') {
        this.getAllWorks();
      } else {
        this.getAllMaterials();
      }
    },
  },
};
</script>

<style lang="scss" >
.est-edit {
  &_header {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 17px;
    &_controls {
      flex: 0 0 60%;
      width: 60%;
      max-width: 60%;
      .card {
        padding: 13px 23px 0px 23px;
        align-items: baseline;
      }
    }
    &_info {
      flex: auto;
      padding: 7px 46px 7px 0;
      p {
        &.title {
          font-size: 26px;
          font-weight: 100;
        }
        &.parameter {
          font-size: 16px;
          font-weight: 100;
          color: $darkgray_color;
          margin-bottom: 2px;
          line-height: 1.1;
          span {
            color: $main_color;
          }
        }
        &.total {
          font-size: 30px;
          margin-top: 7px;
          span {
            font-size: 36px;
            font-weight: 400;
            color: $info_color;
          }
        }
      }
    }
  }
  .p-fieldset .p-fieldset-legend {
    padding: 0;
    background: $lightgray_color;
    border-color: $lightgray_color;
  }
  .legend_header {
    &_wrap {
      align-items: center;
      font-size: 18px;
      font-weight: 100;
      display: flex;
      padding-left: 20px;
      &_total {
        color: $info_color;
        font-weight: 400;
      }
      .p-button.p-button-icon-only.p-button-rounded {
        height: 34px;
      }
    }
  }
}
#estimate_container {
  .card {
    padding-top: 20px;
  }
  .p-datatable.p-datatable-sm {
    .p-datatable-tbody {
      > tr {
        > td {
          padding: 3px;
          .p-inputnumber {
            max-width: 100px;
            .p-inputnumber-input {
              padding: 2px;
              text-align: center;
              width: 40px;
              font-size: 14px;
            }
            .p-button.p-button-icon-only {
              width: 24px;
              padding: 2px;
            }
          }
        }
      }
    }
  }
}
</style>