<template>
    <div class="container">
        <Breadcrumb :home="home" :model="items"></Breadcrumb>
        <!-- <div class="breadcrumbs">
            <ul>
                <li><a href="">Главная</a></li>
                <li>Мои сметы</li>
            </ul>
        </div> -->
    </div>
</template>

<script>
export default {
    props: ['items'],
    data() {
        return {
            home: {
                icon: 'pi pi-home', 
                to: '/',
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .breadcrumbs {
        padding-top: 17px;
        padding-bottom: 15px;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            li {
                color: $darkgray_color;
                font-size: 14px;
                margin-right: 20px;
                position: relative;
                a {
                    color: $darkgray_color;
                }
                &:after {
                    content: '»';
                    position: absolute;
                    top: 50%;
                    right: -14px;
                    transform: translateY(-50%);
                }
                &:last-of-type::after {display: none;}
            }
        }
    }
</style>