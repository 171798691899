<template>
  <page-title>Редактирование шаблона</page-title>

  <div class="container temp-edit">
    <div class="temp-edit_header">
      <div class="temp-edit_header_controls">
        <div class="card flex flex-wrap">
          <div class="col-9 column">
            <div class="form_field">
              <label for="template_name">
                <span>Наименование шаблона</span>
                <InputText
                  type="text"
                  id="template_name"
                  class="w-full"
                  v-model="templateName"
                  placeholder="Название шаблона"
                />
              </label>
            </div>
          </div>
          <div class="col-3 column flex flex-wrap">
            <div class="form_field w-full">
              <label>
                <span>&nbsp;</span>
                <Button
                  label="Сохранить"
                  class="w-full"
                  style="height: 38px;"
                  @click="saveTemplate"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="temp-edit_header_info">
        <p class="title">{{templateName}}</p>
      </div>-->
    </div>

    <div id="estimate_container" ref="estimate_container">
      <div class="card" v-for="step in templateData" :key="step.id">
        <!-- <pre style="height: 60vh; overflow: scroll; font-family: monospace;">{{templateData}}</pre> -->
        <Fieldset :legend="step.s_name" :toggleable="false" class="mb-2 pt-0">
          <template #legend>
            <div class="legend_header_wrap">
              <b>Этап:&nbsp;</b> {{step.s_name}}
              <Button
                @click="editStepName(step.s_id)"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-warn p-button-text ml-2"
                title="Изменить название этапа"
              />
              <Button
                @click="confirmDeleteStep(step.s_id)"
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger p-button-text"
                title="Удалить весь этап"
              />
            </div>
          </template>
          <div
            :id="'estimate_step_' + step.s_id"
            :ref="'estimate_step_' + step.s_id"
            v-for="workflow in step.workflows"
            :key="workflow"
          >
            <Fieldset :legend="workflow.w_name" :toggleable="false" class="mb-4">
              <template #legend>
                <div class="legend_header_wrap">
                  <b>Раздел:&nbsp;</b> {{workflow.w_name}}
                  <Button
                    @click="editWorkflowName(step.s_id, workflow.w_id)"
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-warn p-button-text ml-2"
                    title="Изменить название раздела"
                  />
                  <Button
                    @click="confirmDeleteWorkflow(workflow.w_id)"
                    icon="pi pi-trash"
                    class="p-button-rounded p-button-danger p-button-text"
                    title="Удалить раздел"
                  />
                </div>
              </template>
              <DataTable
                :value="workflow.materials"
                class="p-datatable-sm mb-0"
                responsiveLayout="scroll"
                showGridlines 
              >
                <template #header>Материалы</template>
                <template #empty>Материалы не указаны</template>
                <Column field="name" header="Наименование" class="tmp_estimate_col_name"></Column>
                <Column field="vendor" header="Поставщик" class="tmp_estimate_col_vendor"></Column>
                <Column field="article" header="Артикул" class="tmp_estimate_col_article"></Column>
                <Column field="type" class="tmp_estimate_col_type">
                    <template #body="slotProps">
                    <i :class="formatType(slotProps.data.type)"></i>
                    </template>
                </Column>
                <Column field="unit" header="Ед.изм." class="tmp_estimate_col_unit"></Column>
                <Column header="&mdash;" class="tmp_estimate_col_markup"></Column>
                <Column class="tmp_estimate_col_controls">
                  <template #body="slotProps">
                    <Button
                      @click="confirmDeleteObjectFromWrokflow($event, workflow.w_id, slotProps.data.id)"
                      icon="pi pi-trash"
                      class="p-button-rounded p-button-danger p-button-outlined"
                    />
                  </template>
                </Column>
              </DataTable>
              <DataTable
                :value="workflow.works"
                class="p-datatable-sm mb-0"
                responsiveLayout="scroll"
                showGridlines 
              >
                <template #header>Работы</template>
                <template #empty>Работы не указаны</template>
                <Column field="name" header="Наименование" class="tmp_estimate_col_name"></Column>
                <Column header="&mdash;" class="tmp_estimate_col_vendor"></Column>
                <Column field="article" header="Артикул" class="tmp_estimate_col_article"></Column>
                <Column field="type" class="tmp_estimate_col_type">
                    <template #body="slotProps">
                    <i :class="formatType(slotProps.data.type)"></i>
                    </template>
                </Column>
                <Column field="unit" header="Ед.изм." class="tmp_estimate_col_unit"></Column>
                <Column header="&mdash;" class="tmp_estimate_col_markup"></Column>
                <Column class="tmp_estimate_col_controls">
                  <template #body="slotProps">
                    <Button
                      @click="confirmDeleteObjectFromWrokflow($event, workflow.w_id, slotProps.data.id)"
                      icon="pi pi-trash"
                      class="p-button-rounded p-button-danger p-button-outlined"
                    />
                  </template>
                </Column>
              </DataTable>
              <DataTable
                :value="workflow.expenses"
                class="p-datatable-sm mb-0"
                responsiveLayout="scroll"
                v-if="workflow.expenses.length > 0"
                showGridlines 
              >
                <template #header>Дополнительные расходы</template>
                <template #empty>Дополнительные расходы не указаны</template>
                
                <Column field="name" header="Наименование" class="tmp_estimate_col_name"></Column>
                <Column header="&mdash;" class="tmp_estimate_col_vendor"></Column>
                <Column header="&mdash;" class="tmp_estimate_col_article"></Column>
                <Column field="type" class="tmp_estimate_col_type">
                    <template #body="slotProps">
                        <i :class="formatType(slotProps.data.type)"></i>
                    </template>
                </Column>
                <Column header="&mdash;" class="tmp_estimate_col_unit"></Column>
                <Column field="markup" header="Наценка" class="tmp_estimate_col_markup">
                  <template #body="slotProps">
                    <div>{{slotProps.data.markup}}%</div>
                  </template>
                </Column>
                <Column class="tmp_estimate_col_controls">
                  <template #body="slotProps">
                    <Button
                      @click="confirmDeleteExpenseFromWorkflow($event, workflow.w_id, slotProps.data.id)"
                      icon="pi pi-trash"
                      class="p-button-rounded p-button-danger p-button-outlined"
                    />
                  </template>
                </Column>
              </DataTable>

              <Button
                label="Привязать материалы"
                icon="pi pi-plus"
                class="p-button-secondary mr-2 mt-4"
                @click="openAddMaterialDalog(step.s_id, workflow.w_id, workflow.materials)"
              />
              <Button
                label="Привязать работы"
                icon="pi pi-plus"
                class="p-button-help mt-4"
                @click="openAddWorksDalog(step.s_id, workflow.w_id, workflow.works)"
              />
              <Button
                label="Привязать расходы"
                icon="pi pi-plus"
                class="p-button-info ml-3 mt-4"
                @click="openAddExpenseDalog(step.s_id, workflow.w_id, workflow.expenses)"
              />
              <!-- <pre>{{addExpensesData}}</pre> -->
            </Fieldset>
          </div>

          <Button
            label="Создать раздел"
            icon="pi pi-plus"
            class="m-4 mr-2"
            @click="toggleWorkflowsOverlay($event, step.s_id)"
            aria:haspopup="true"
            aria-controls="overlay_panel_workflows"
          ></Button>
        </Fieldset>
      </div>

      <Button
        label="Добавить этап"
        icon="pi pi-plus"
        class="m-4 mr-2"
        @click="toggleStepOverlay"
        aria:haspopup="true"
        aria-controls="overlay_panel"
      ></Button>

      <OverlayPanel
        ref="opStep"
        appendTo="body"
        :showCloseIcon="true"
        id="overlay_panel"
        style="width: 450px"
        :breakpoints="{'960px': '75vw'}"
      >
        <div class="flex flex-wrap">
          <div class="col-12">
            <div class="form_field">
              <label for="newStep_title">
                <span>Название этапа</span>
                <InputText type="text" id="newStep_title" class="w-full" v-model="newStepTitle" />
              </label>
            </div>
          </div>
          <div class="col-12">
            <Button
              label="Применить"
              icon="pi pi-check"
              @click="createStep(templateId, newStepTitle)"
            />
          </div>
        </div>
      </OverlayPanel>

      <OverlayPanel
        ref="opWorkflow"
        appendTo="body"
        :showCloseIcon="true"
        id="overlay_panel_workflows"
        style="width: 450px"
        :breakpoints="{'960px': '75vw'}"
      >
        <div class="flex flex-wrap">
          <div class="col-12">
            <div class="form_field">
              <label for="newWorkflow_title">
                <span>Название работ</span>
                <InputText
                  type="text"
                  id="newWorkflow_title"
                  class="w-full"
                  v-model="newWorkflowTitle"
                />
              </label>
            </div>
          </div>
          <div class="col-12">
            <Button
              label="Применить"
              icon="pi pi-check"
              @click="createWorkflow(templateId, newWorkflowTitle)"
            />
          </div>
        </div>
      </OverlayPanel>

      <Button label="Сохранить шаблон" icon="pi pi-check" class="m-4 ml-2" @click="saveTemplate" />
    </div>
  </div>

  <Dialog
    header="База материалов"
    v-model:visible="addMaterialDialogVisible"
    :maximizable="true"
    :style="{width: '70vw'}"
  >
    <DataTable
      :value="preparedMaterials"
      class="p-datatable-sm"
      responsiveLayout="scroll"
      :paginator="true"
      :rows="30"
      v-model:selection="selectedMaterials"
      data-key="id"
      :rowClass="rowClass"
      selectionMode="multiple"
      :metaKeySelection="false"
        v-model:filters="filters"
      filterDisplay="row"
      showGridlines
      :globalFilterFields="['name','article','vendor']"

      :lazy="true"
      :totalRecords="totalMaterialsRecords"
      @page="onPage($event, 'material')"
      @sort="onSort($event, 'material')"
      @filter="onFilter($event, 'material')" 
    >
      <template #empty>Материалы не найдены</template>
      <Column field="type" class="tmp_estimate_col_type">
        <template #body="slotProps">
          <i :class="formatType(slotProps.data.type)"></i>
        </template>
      </Column>
      <Column
        field="name"
        header="Наименование"
        class="tmp_estimate_col_name"
        :showFilterMatchModes="false"
        :showFilterOperator="false"
        :showFilterMenu="false"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter p-inputtext-sm"
            :placeholder="`Поиск`"
            v-tooltip.top.focus="'Введите название'"
          />
        </template>
      </Column>
      <Column
        field="article"
        header="Артикул"
        class="tmp_estimate_col_article"
        :showFilterMatchModes="false"
        :showFilterOperator="false"
        :showFilterMenu="false"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter p-inputtext-sm"
            :placeholder="`Поиск`"
            v-tooltip.top.focus="'Введите артикул'"
          />
        </template>
      </Column>
      <Column field="unit" header="Ед.изм." class="tmp_estimate_col_unit"></Column>
      <Column
        field="vendor"
        header="Поставщик"
        class="tmp_estimate_col_vendor"
        :showFilterMatchModes="false"
        :showFilterOperator="false"
        :showFilterMenu="false"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter p-inputtext-sm"
            :placeholder="`Поиск`"
            v-tooltip.top.focus="'Введите название поставщика'"
          />
        </template>
      </Column>
    </DataTable>
    <template #footer>
      <Button
        label="Отмена"
        icon="pi pi-times"
        @click="addMaterialDialogVisible = false"
        class="p-button-text"
      />
      <Button label="Добавить выбранное" icon="pi pi-check" @click="addMaterial" />
    </template>
  </Dialog>

  <Dialog
    header="База работ"
    v-model:visible="addWorksDialogVisible"
    :maximizable="true"
    :style="{width: '70vw'}"
  >
    <DataTable
      :value="preparedWorks"
      class="p-datatable-sm"
      responsiveLayout="scroll"
      :paginator="true"
      :rows="30"
      v-model:selection="selectedWorks"
      data-key="id"
      :rowClass="rowClass"
      selectionMode="multiple"
      :metaKeySelection="false"
        v-model:filters="filters"
      filterDisplay="row"
      showGridlines
      :globalFilterFields="['name','article']"

      :lazy="true"
      :totalRecords="totalWorksRecords"
      @page="onPage($event, 'works')"
      @sort="onSort($event, 'works')"
      @filter="onFilter($event, 'works')" 
    >
      <template #empty>Работы не найдены</template>
      <Column field="type" class="tmp_estimate_col_type">
        <template #body="slotProps">
          <i :class="formatType(slotProps.data.type)"></i>
        </template>
      </Column>
      <Column
        field="name"
        header="Наименование"
        class="tmp_estimate_col_name"
        :showFilterMatchModes="false"
        :showFilterOperator="false"
        :showFilterMenu="false"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter p-inputtext-sm"
            :placeholder="`Поиск`"
            v-tooltip.top.focus="'Введите название'"
          />
        </template>
      </Column>
      <Column
        field="article"
        header="Артикул"
        class="tmp_estimate_col_article"
        :showFilterMatchModes="false"
        :showFilterOperator="false"
        :showFilterMenu="false"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter p-inputtext-sm"
            :placeholder="`Поиск`"
            v-tooltip.top.focus="'Введите артикул'"
          />
        </template>
      </Column>
      <Column field="unit" header="Ед.изм." class="tmp_estimate_col_unit"></Column>
    </DataTable>
    <template #footer>
      <Button
        label="Отмена"
        icon="pi pi-times"
        @click="addWorksDialogVisible = false"
        class="p-button-text"
      />
      <Button label="Добавить выбранное" icon="pi pi-check" @click="addWorks" />
    </template>
  </Dialog>

  <Dialog
    header="База расходов"
    v-model:visible="addExpensesDialogVisible"
    :maximizable="true"
    :style="{width: '70vw'}"
  >
    <DataTable
      :value="preparedExpenses"
      class="p-datatable-sm"
      responsiveLayout="scroll"
      :paginator="true"
      :rows="30"
      v-model:selection="selectedExpenses"
      data-key="id"
      :rowClass="rowClass"
      selectionMode="multiple"
      :metaKeySelection="false"
        v-model:filters="filters"
      filterDisplay="row"
      showGridlines
      :globalFilterFields="['name']"
    >
      <template #empty>Расходы не найдены</template>
        <Column field="type" class="tmp_estimate_col_type" sortable>
            <template #body="slotProps">
                <i :class="formatType(slotProps.data.type)"></i>
            </template>
        </Column>
      <Column
        field="name"
        header="Наименование"
        class="tmp_estimate_col_name"
        :showFilterMatchModes="false"
        :showFilterOperator="false"
        :showFilterMenu="false"
      >
        <template #filter="{filterModel,filterCallback}">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter p-inputtext-sm"
            :placeholder="`Поиск`"
            v-tooltip.top.focus="'Введите название'"
          />
        </template>
      </Column>
      <Column
        field="markup"
        header="Наценка"
        class="tmp_estimate_col_markup"
      >
        <template #body="slotProps">
          <span>{{slotProps.data.markup}}%</span>
        </template>
      </Column>
    </DataTable>

    <template #footer>
      <Button
        label="Отмена"
        icon="pi pi-times"
        @click="addExpensesDialogVisible = false"
        class="p-button-text"
      />
      <Button label="Добавить выбранное" icon="pi pi-check" @click="addExpense"/>
    </template>
  </Dialog>

  <Dialog
    header="Редактирование этапа"
    v-model:visible="editStepDialogVisible"
  >

    <InputText type="text" v-model="editedStep.title" class="w-full"/>

    <template #footer>
      <Button
        label="Отмена"
        icon="pi pi-times"
        @click="editStepDialogVisible = false"
        class="p-button-text"
      />
      <Button label="Изменить" icon="pi pi-check" @click="changeStep"/>
    </template>
  </Dialog>

  <Dialog
    header="Редактирование раздела"
    v-model:visible="editWorkflowDialogVisible"
  >

    <InputText type="text" v-model="editedWorkflow.title" class="w-full"/>

    <template #footer>
      <Button
        label="Отмена"
        icon="pi pi-times"
        @click="editWorkflowDialogVisible = false"
        class="p-button-text"
      />
      <Button label="Изменить" icon="pi pi-check" @click="changeWorkflow"/>
    </template>
  </Dialog>

</template>

<script>
import EstimateService from "../services/EstimateService";
import { FilterMatchMode } from "primevue/api";

export default {
  props: ["userData"],
  data() {
    return {
      templateId: this.$route.params.id,
      templateData: null,
      templateName: null,
      newStepTitle: null,
      newWorkflowTitle: null,
      newWorkflowStep: null,
      addMaterialDialogVisible: false,
      addWorksDialogVisible: false,
      addExpensesDialogVisible: false,
      addMaterialData: null,
      addWorksData: null,
      addExpensesData: null,
      allObjects: [],
      allMaterials: [],
      allWorks: [],
      allExpenses: [],
      selectedMaterials: null,
      selectedWorks: null,
      selectedExpenses: null,

      filters: {
        //'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        article: { value: null, matchMode: FilterMatchMode.CONTAINS },
        vendor: { value: null, matchMode: FilterMatchMode.CONTAINS }
      },

      editStepDialogVisible: false,
      editedStep: {},
      editWorkflowDialogVisible: false,
      editedWorkflow: {},

      totalRecords: 0,
      totalMaterialsRecords: 0,
      totalWorksRecords: 0,
      lazyParams: {},
    };
  },
  EstimateService: null,
  computed: {
    preparedMaterials() {
      // исключение из списка тех материалов, что уже есть в текущих работах
      const that = this;

      var intersectionMaterials = this.addMaterialData.materials.map(function (v) {
        return Object.assign({},
          that.allObjects.find(function (v1) {
            return v1.id === v.id;
          }),
          v
        );
      });

      let output = [];

      this.allMaterials.map((item) => {
          // if (item.type == 'material') {
            if (intersectionMaterials.find((itemMaterial) => itemMaterial.id == item.id)) {
            item.class = "p-disabled";
            } else {
            item.class = "";
            }
            output.push(item);
          // }
      });

      return output;
    },
    preparedWorks() {
      // исключение из списка тех работ, что уже есть в текущих работах
      const that = this;

      var intersectionWorks = this.addWorksData.works.map(function (v) {
        return Object.assign(
          {},
          that.allObjects.find(function (v1) {
            return v1.id === v.id;
          }),
          v
        );
      });

      let output = [];

      this.allWorks.map((item) => {
          // if (item.type == 'works') {
            if (intersectionWorks.find((itemWorks) => itemWorks.id == item.id)) {
            item.class = "p-disabled";
            } else {
            item.class = "";
            }
            output.push(item);
          // }
      });

      return output;
    },
    preparedExpenses() {
      // исключение из списка тех расходов, что уже есть в текущих работах. Можно добавить только по одному расходу на работы и материалы.
      const that = this;

      var intersectionExpenses = this.addExpensesData.expenses.map(function (v) {
        return Object.assign(
          {},
          that.allExpenses.find(function (v1) {
            return v1.id === v.id;
          }),
          v
        );
      });

      let output = [];

        // let lockMaterials = false;
        // let lockWorks = false;
        // if (this.addExpensesData.expenses.find((itemData) => itemData.type == 'material')) {
        //     lockMaterials = true;
        // }
        // if (this.addExpensesData.expenses.find((itemData) => itemData.type == 'works')) {
        //     lockWorks = true;
        // }

      this.allExpenses.map((item) => {
        if (intersectionExpenses.find((itemExpense) => itemExpense.id == item.id)) {
            item.class = "p-disabled";
        } else {
            // if ((item.type == 'material' && lockMaterials) || (item.type == 'works' && lockWorks)) {
            //     item.class = "p-disabled";
            // } else {
                item.class = "";
            // }
        }
        output.push(item);
      });

      return output;
    },
  },
  created() {
    this.EstimateService = new EstimateService();
  },
  mounted() {
    let checkUserData = setInterval(() => {
      if (this.userData != null) {
        this.getTemplateData(this.templateId);
        this.getAllObjects();
        this.getAllMaterials();
        this.getAllWorks();
        this.getAllExpenses();
        clearInterval(checkUserData);
      }
    }, 200);
    this.lazyParams = {
      first: 0,
      rows: 30,
      sortField: null,
      sortOrder: null,
      filters: this.filters
    };
  },
  methods: {
    formatType(value) {
      let output = "";
      switch (value) {
        case "material":
          output = "pi pi-th-large text-cyan-600";
          break;
        case "works":
          output = "pi pi-user text-green-600";
          break;
      }
      return output;
    },
    rowClass(data) {
      // стилизация строки в таблице материалов
      return data.class === "p-disabled" ? "p-disabled" : null;
    },
    getAllObjects() {
      // получение всех материалов из БД
      this.EstimateService.getAllObjects(JSON.stringify( this.lazyParams )).then((data) => {
        if (data.status) {
          this.allObjects = data.message;
          this.totalRecords = data.totalRecords;
        }
      });
    },
    getAllWorks() {
      this.lazyParams.filters.type = {value: 'works'};
      this.EstimateService.getAllObjects(JSON.stringify( this.lazyParams )).then((data) => {
        // console.log(data);
        if (data.status) {
          this.allWorks = data.message;
          this.totalWorksRecords = data.totalRecords;
        }
      });
    },
    getAllMaterials() {
      this.lazyParams.filters.type = {value: 'material'};
      this.EstimateService.getAllObjects(JSON.stringify( this.lazyParams )).then((data) => {
        // console.log(data);
        if (data.status) {
          this.allMaterials = data.message;
          this.totalMaterialsRecords = data.totalRecords;
        }
      });
    },
    getAllExpenses() {
      // получение всех доп.расходов из БД
      this.EstimateService.getAllExpenses().then((data) => {
        if (data.status) {
          this.allExpenses = data.message;
        }
      });
    },
    toggleStepOverlay(event) {
      this.$refs.opStep.toggle(event);
      this.newStepTitle = null;
    },
    toggleWorkflowsOverlay(event, step_id) {
      this.$refs.opWorkflow.toggle(event);
      this.newWorkflowTitle = null;
      this.newWorkflowStep = step_id;
    },
    getTemplateData(id) {
      this.EstimateService.getTemplateData(id).then((data) => {
        if (data.status) {
          this.templateData = data.message;
          this.templateName = data.name;
        } else {
          this.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    },
    createStep(template_id, step_title) {
      if (step_title) {
        const that = this;
        const formData = new FormData();
        formData.append("action", "createStep");
        formData.append("template_id", template_id);
        formData.append("step_title", step_title);

        this.EstimateService.createStep(formData).then((data) => {
          if (data.status) {
            that.$toast.add({
              severity: "success",
              detail: data.message,
              life: 3000,
            });
            that.getTemplateData(that.templateId);
            // let tempStep = {
            //     s_id: Number(data.new_id),
            //     s_name: step_title,
            //     workflows: []};
            // that.templateData.push(tempStep);
            that.$refs.opStep.hide();
          } else {
            that.$toast.add({
              severity: "error",
              detail: data.message,
              life: 3000,
            });
          }
        });
      } else {
        this.$toast.add({
          severity: "warn",
          detail: "Название этапа обязательно",
          life: 3000,
        });
      }
    },
    createWorkflow(template_id, workflow_title) {
      if (workflow_title && this.newWorkflowStep) {
        const that = this;
        const formData = new FormData();
        formData.append("action", "createWorkflow");
        formData.append("template_id", template_id);
        formData.append("step_id", this.newWorkflowStep);
        formData.append("workflow_title", workflow_title);

        this.EstimateService.createWorkflow(formData).then((data) => {
          if (data.status) {
            that.$toast.add({
              severity: "success",
              detail: data.message,
              life: 3000,
            });
            that.getTemplateData(that.templateId);
            that.$refs.opWorkflow.hide();
          } else {
            that.$toast.add({
              severity: "error",
              detail: data.message,
              life: 3000,
            });
          }
        });
      } else {
        this.$toast.add({
          severity: "warn",
          detail: "Наименование работ обязательно",
          life: 3000,
        });
      }
    },
    editStepName(step_id) {
      this.templateData.find(item => {
        if (item.s_id == step_id) {
          this.editedStep.title = item.s_name;
          this.editedStep.id = item.s_id;
          this.editStepDialogVisible = true;
        }
      });
    },
    changeStep() {
      const that = this;
      const formData = new FormData();
      formData.append("action", "changeStep");
      formData.append("step_id", this.editedStep.id);
      formData.append("step_title", this.editedStep.title);

      this.EstimateService.changeStep(formData).then((data) => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 3000,
          });
          that.getTemplateData(that.templateId);
          that.editStepDialogVisible = false;
          that.editedStep = {};
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    },
    confirmDeleteStep(step_id) {
      // запрос подтверждения удаления этапа
      const that = this;
      this.$confirm.require({
        // target: event.currentTarget,
        header: "Подтверждение",
        message:
          "Вы уверены? Вместе с этапом будут удалены все внутренние разделы",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          that.deleteStep(step_id);
        },
      });
    },
    deleteStep(id) {
      // удаление этапа и всех связанных с ним работ из БД
      const that = this;
      this.EstimateService.deleteStep(id).then((data) => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 3000,
          });
          that.getTemplateData(that.templateId);
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    },
    editWorkflowName(step_id, workflow_id) {
      for (let step of this.templateData) {
        if (step.s_id == step_id) {
          step.workflows.find(item => {
            if (item.w_id == workflow_id) {
              this.editedWorkflow.title = item.w_name;
              this.editedWorkflow.id = item.w_id;
              this.editWorkflowDialogVisible = true;
            }
          });
        }
      }
    },
    changeWorkflow() {
      const that = this;
      const formData = new FormData();
      formData.append("action", "changeWorkflow");
      formData.append("workflow_id", this.editedWorkflow.id);
      formData.append("workflow_title", this.editedWorkflow.title);

      this.EstimateService.changeWorkflow(formData).then((data) => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 3000,
          });
          that.getTemplateData(that.templateId);
          that.editWorkflowDialogVisible = false;
          that.editedWorkflow = {};
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    },
    confirmDeleteWorkflow(workflow_id) {
      // запрос подтверждения удаления работ
      const that = this;
      this.$confirm.require({
        // target: event.currentTarget,
        message: "Точно удалить?",
        header: "Подтверждение",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          that.deleteWorkflow(workflow_id);
        },
      });
    },
    deleteWorkflow(id) {
      // удаление работ из БД
      const that = this;
      this.EstimateService.deleteWorkflow(id).then((data) => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 3000,
          });
          that.getTemplateData(that.templateId);
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    },
    openAddMaterialDalog(s_id, w_id, materials) {
      // формирование массива для добавления новых материалов в работы
      this.addMaterialData = {
        s_id: s_id,
        w_id: w_id,
        materials: materials,
      };

      this.addMaterialDialogVisible = true;
    },
    openAddWorksDalog(s_id, w_id, works) {
      // формирование массива для добавления новых работ в работы
      this.addWorksData = {
        s_id: s_id,
        w_id: w_id,
        works: works,
      };

      this.addWorksDialogVisible = true;
    },
    openAddExpenseDalog(s_id, w_id, expenses) {
      // формирование массива для добавления новых расходов в процесс
      this.addExpensesData = {
        s_id: s_id,
        w_id: w_id,
        expenses: expenses,
      };

      this.addExpensesDialogVisible = true;
    },
    addMaterial() {
      // добавление материалов в работы

      this.addMaterialData.materials = this.selectedMaterials;

      const that = this;
      const formData = new FormData();
      formData.append("action", "addMaterialToWorkflow");
      formData.append("w_id", this.addMaterialData.w_id);
      formData.append(
        "materials",
        JSON.stringify(this.addMaterialData.materials)
      );

      this.EstimateService.addMaterialToWorkflow(formData).then((data) => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 3000,
          });
          that.getTemplateData(that.templateId);
          that.addMaterialDialogVisible = false;
          that.addMaterialData = null;
          that.selectedMaterials = null;
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    },
    addWorks() {
      // добавление работ в работы

      this.addWorksData.works = this.selectedWorks;

      const that = this;
      const formData = new FormData();
      formData.append("action", "addWorksToWorkflow");
      formData.append("w_id", this.addWorksData.w_id);
      formData.append(
        "works",
        JSON.stringify(this.addWorksData.works)
      );

      this.EstimateService.addWorksToWorkflow(formData).then((data) => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 3000,
          });
          that.getTemplateData(that.templateId);
          that.addWorksDialogVisible = false;
          that.addWorksData = null;
          that.selectedWorks = null;
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    },
    addExpense() {
      // добавление доп.расходов в работы
    
        // проверяем, чтобы в выбранных расходах было не более, чем по одной позиции на каждый тип расхода
        // let addedMaterialsExpenses = this.selectedExpenses.filter(item => item.type == 'material');
        // let addedWorksExpenses = this.selectedExpenses.filter(item => item.type == 'works');

        // if (addedMaterialsExpenses.length > 1 || addedWorksExpenses.length > 1) {
        //     this.$toast.add({
        //         severity: "warn",
        //         detail: "На материалы и работы допускатеся максимум по одному расходу",
        //         life: 3000,
        //     });
        // } else {
            this.addExpensesData.expenses = this.selectedExpenses;

            const that = this;
            const formData = new FormData();
            formData.append("action", "addExpenseToWorkflow");
            formData.append("w_id", this.addExpensesData.w_id);
            formData.append(
                "expenses",
                JSON.stringify(this.addExpensesData.expenses)
            );

            this.EstimateService.addExpenseToWorkflow(formData).then((data) => {
                if (data.status) {
                that.$toast.add({
                    severity: "success",
                    detail: data.message,
                    life: 3000,
                });
                that.getTemplateData(that.templateId);
                that.addExpensesDialogVisible = false;
                that.addExpensesData = null;
                that.selectedExpenses = null;
                } else {
                that.$toast.add({
                    severity: "error",
                    detail: data.message,
                    life: 3000,
                });
                }
            });
        // }
    },
    confirmDeleteObjectFromWrokflow(event, workflow_id, material_id) {
      // запрос подтверждения удаления материала из работ
      const that = this;
      this.$confirm.require({
        target: event.currentTarget,
        message: "Отвязать этот объект?",
        header: "Подтверждение",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          that.removeObjectFromWorkflow(workflow_id, material_id);
        },
      });
    },
    removeObjectFromWorkflow(w_id, m_id) {
      // удаление связки материал-работы
      const formData = new FormData();
      const that = this;
      formData.append("action", "removeObjectFromWorkflow");
      formData.append("w_id", w_id);
      formData.append("m_id", m_id);

      this.EstimateService.removeObjectFromWorkflow(formData).then((data) => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 3000,
          });
          that.getTemplateData(that.templateId);
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    },
    confirmDeleteExpenseFromWorkflow(event, workflow_id, expense_id) {
      // запрос подтверждения удаления доп.расходов из работ
      const that = this;
      this.$confirm.require({
        target: event.currentTarget,
        message: "Удалить эти расходы?",
        header: "Подтверждение",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          that.removeExpenseFromWorkflow(workflow_id, expense_id);
        },
      });
    },
    removeExpenseFromWorkflow(w_id, e_id) {
      // удаление связки расходы-работы
      const formData = new FormData();
      const that = this;
      formData.append("action", "removeExpenseFromWorkflow");
      formData.append("w_id", w_id);
      formData.append("e_id", e_id);

      this.EstimateService.removeExpenseFromWorkflow(formData).then((data) => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 3000,
          });
          that.getTemplateData(that.templateId);
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    },
    saveTemplate() {
      const that = this;
      const formData = new FormData();
      formData.append("action", "saveTemplate");
      // formData.append('token', localStorage.pd_token);
      // formData.append('c_user_id', localStorage.starta_id);
      formData.append("id", this.templateId);
      formData.append("name", this.templateName);

      this.EstimateService.saveTemplate(formData).then((data) => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 3000,
          });
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    },
    onPage(event, type) {
      this.lazyParams = event;
      if (type == 'works') {
        this.getAllWorks();
      } else {
        this.getAllMaterials();
      }
      
    },
    onSort(event, type) {
      this.lazyParams = event;
      if (type == 'works') {
        this.getAllWorks();
      } else {
        this.getAllMaterials();
      }
    },
    onFilter(event, type) {
      console.log(event);
      this.lazyParams.filters = this.filters;
      this.lazyParams.first = 0;
      if (type == 'works') {
        this.getAllWorks();
      } else {
        this.getAllMaterials();
      }
    },
  },
};
</script>

<style lang="scss">
.temp-edit {
  &_header {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 17px;
    &_controls {
      flex: 0 0 60%;
      width: 60%;
      max-width: 60%;
      .card {
        padding: 13px 23px 0px 23px;
      }
    }
    &_info {
      flex: auto;
      padding: 7px 46px;
      p {
        &.title {
          font-size: 26px;
          font-weight: 100;
        }
        &.parameter {
          font-size: 20px;
          font-weight: 100;
          color: $darkgray_color;
          margin-bottom: 2px;
          span {
            color: $main_color;
          }
        }
        &.total {
          font-size: 30px;
          margin-top: 7px;
          span {
            font-size: 36px;
            font-weight: 400;
            color: $info_color;
          }
        }
      }
    }
  }
  .p-fieldset .p-fieldset-legend {
    padding: 0;
    background: $lightgray_color;
    border-color: $lightgray_color;
  }
  .legend_header {
    &_wrap {
      align-items: center;
      font-size: 18px;
      font-weight: 100;
      display: flex;
      padding-left: 20px;
      &_total {
        color: $info_color;
        font-weight: 400;
      }
      .p-button.p-button-icon-only.p-button-rounded {
        height: 34px;
      }
    }
  }
}
#estimate_container {
  .card {
    padding-top: 20px;
  }
  .p-column-title {
    font-size: 14px;
  }
  .p-datatable.p-datatable-sm {
    .p-datatable-tbody {
      > tr {
        > td {
          padding: 3px;
          .p-inputnumber {
            max-width: 100px;
            .p-inputnumber-input {
              padding: 2px;
              text-align: center;
              font-size: 14px;
              width: 40px;
            }
            .p-button.p-button-icon-only {
              width: 24px;
              padding: 2px;
            }
          }
        }
      }
    }
  }
}
</style>