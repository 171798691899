<template>
  <div class="container login">
    <div class="surface-card p-4 shadow-2 border-round w-full w-10 md:w-6 lg:w-4">
      <div class="text-center mb-5">
        <img src="@/assets/img/logo.svg" alt="ПапаDom Estimateion" height="50" class="mb-1 mt-2" />
        <!-- <div class="text-900 text-3xl font-medium mb-3">Welcome Back</div> -->
        <!-- <span class="text-600 font-medium line-height-3">Don't have an account?</span> -->
        <!-- <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Create today!</a> -->
      </div>

      <form @submit.prevent="login" ref="loginForm">
        <label for="email" class="block text-900 font-medium mb-2">E-mail</label>
        <InputText
          id="email"
          v-model="email"
          type="email"
          class="w-full mb-3"
          placeholder="Введите почту"
          required
        />

        <label for="password" class="block text-900 font-medium mb-2">Пароль</label>
        <Password
          id="password"
          v-model="password"
          :feedback="false"
          placeholder="Введите пароль"
          :toggleMask="true"
          class="w-full mb-3"
          inputClass="w-full"
          required
        ></Password>

        <div class="flex align-items-center justify-content-between mb-2">
          <div class="flex align-items-center">
            <a
              class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
            >Забыли пароль?</a>
            <!-- <Checkbox id="rememberme1" :binary="true" v-model="checked" class="mr-2"></Checkbox>
            <label for="rememberme1">Remember me</label>-->
          </div>
          <Button type="submit" label="Войти" icon="pi pi-user" class="p-button"></Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AuthService from "../services/AuthService";
export default {
  props: ['userData'],
  data() {
    return {
      // token: null,
      email: "",
      password: "",
      checked: false,
    };
  },
  AuthService: null,
  created() {
    this.AuthService = new AuthService();
  },
  // mounted() {
  //   this.token = this.AuthService.getCookie("pd_token");
  //   if (!this.token) {
  //     console.log('asdad');
  //   }
  //   // this.AuthService.checkAuth(this);
  // },
  methods: {
    login() {
      var email = this.$refs.loginForm.email.value;
      var password = this.$refs.loginForm.password.value;
      const formData = new FormData();
      const that = this;

      formData.append("email", email);
      formData.append("password", password);

      this.AuthService.login(formData).then((data) => {
        // console.log(data);
        if (data.status) {
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 4000,
          });
          that.$router.push("/");
        } else {
          that.$toast.add({
            severity: "error",
            summary: "Ошибка авторизации",
            detail: data.message,
            life: 4000,
          });
        }
      });
    },
    // checkAuth() {
    //   const that = this;
    //   var token = this.AuthService.getCookie("pd_token");
    //   if (token) {
    //     this.AuthService.validateToken(this).then((data) => {
    //       if (data.status) {
    //         that.$router.push("/");
    //       }
    //     });
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>