<template>
  <page-title>Мой профиль</page-title>

  <div class="container user-edit">
    <div class="flex flex-wrap col-6">
      
        <div class="col-4">
          <div class="form_field mb-1">
            <label for="u_firstname">
              <span>Имя</span>
              <InputText
                type="text"
                id="u_firstname"
                class="w-full"
                v-model="userDataToEdit.firstname"
                placeholder="Укажите своё имя"
              />
            </label>
          </div>
        </div>

        <div class="col-4">
          <div class="form_field mb-1">
            <label for="u_lastname">
              <span>Фамилия</span>
              <InputText
                type="text"
                id="u_lastname"
                class="w-full"
                v-model="userDataToEdit.lastname"
                placeholder="Введите фамилию"
              />
            </label>
          </div>
        </div>

        <div class="col-8">
          <div class="form_field mb-1">
            <label for="u_email">
              <span>E-mail</span>
              <InputText
                type="email"
                id="u_email"
                class="w-full"
                v-model="userDataToEdit.email"
                placeholder="Ваша почта"
              />
            </label>
          </div>
        </div>

        <div class="col-8">
          <div class="form_field mb-1">
            <label for="u_password">
              <span>Пароль</span>
              <Password
                id="u_password"
                class="w-full"
                v-model="userDataToEdit.password"
                toggleMask
                aria-describedby="password-help" 
                autocomplete="new-password"
              />
              <small id="password-help">Заполните это поле, если хотите изменить пароль</small>
            </label>
          </div>
        </div>

        <div class="col-8">
          <div class="form_field mb-1">
            <Button label="Сохранить" @click="saveProfile"/>
          </div>
        </div>
      
    </div>     

  </div>
</template>

<script>
import UserService from "../services/UserService";

export default {
  props: ['userData'],
  data() {
    return {
      userDataToEdit: [],
    }
  },
  UserService: null,
  created() {
    this.UserService = new UserService();
  },
  mounted() {
    let checkUserData = setInterval(() => {
      if (this.userData != null) {
        this.userDataToEdit = this.userData;
        clearInterval(checkUserData);
      }
    }, 200);
  },
  methods: {
    saveProfile() {
      const that = this;
      if (this.userDataToEdit.firstname || this.userDataToEdit.lastname || this.userDataToEdit.email) {
        const formData = new FormData();
        var token = this.UserService.getCookie("pd_token");

        formData.append('action', 'updateProfile');
        formData.append('token', token);
        formData.append('id', this.userDataToEdit.id);
        formData.append('firstname', this.userDataToEdit.firstname);
        formData.append('lastname', this.userDataToEdit.lastname);
        formData.append('email', this.userDataToEdit.email);

        if (this.userDataToEdit.password) {
          formData.append('password', this.userDataToEdit.password);
        }

        this.UserService.saveProfile(formData).then(data => {
          if (data.status) {
            that.$toast.add({
              severity: "success",
              summary: data.message,
              life: 4000,
            });
          } else {
            that.$toast.add({
              severity: "error",
              detail: data.message,
              life: 4000,
            });
          }
        })
      }
    }
  }
}
</script>