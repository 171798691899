<template>
  <page-title>Список разделов</page-title>
  <div class="container">
    <Toolbar class="mb-3 card">
      <template #start>
        <Button
          label="Новый раздел"
          icon="pi pi-plus"
          class="mr-2"
          @click="showNewWorkflowDialog"
        />
      </template>

      <template #end>
        <!-- <Button icon="pi pi-search" class="mr-2" />
            <Button icon="pi pi-calendar" class="p-button-success mr-2" />
            <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>
    <div class="card">
      <DataTable
        :value="workflows"
        responsiveLayout="scroll"
        :paginator="true"
        :rows="10"
        showGridlines 
      >
        <Column field="id" header="ID" style="width: 10%"></Column>
        <Column field="name" header="Наименование" style="width: 50%">
          <template #body="slotProps">
            <router-link :to="'/workflows/' + slotProps.data.id">{{
              slotProps.data.name
            }}</router-link>
          </template>
        </Column>
        <Column field="created" header="Создан" style="width: 15%"></Column>
        <Column field="modified" header="Изменён" style="width: 15%"></Column>
        <Column>
          <template #body="slotProps" style="width: 10%">
            <Button
              @click="this.$router.push('/workflows/' + slotProps.data.id)"
              icon="pi pi-pencil"
              class="p-button-rounded p-button p-button-outlined mr-2"
            />
            <Button
              @click="confirmDeleteWorkflow($event, slotProps.data.id)"
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-outlined"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>

  <Dialog
    header="Новый раздел"
    v-model:visible="displayWorkflowDialog"
    :style="{ width: '460px' }"
  >
    <div class="form_field">
      <label for="template_name"
        ><span>Наименование раздела</span>
        <InputText
          type="text"
          id="workflow_name"
          class="w-full"
          v-model="newWorkflowName"
          placeholder="Введите название"
        />
      </label>
    </div>
    <template #footer>
      <Button
        label="Отмена"
        icon="pi pi-times"
        @click="displayWorkflowDialog = false"
        class="p-button-text"
      />
      <Button
        label="Создать"
        icon="pi pi-check"
        @click="createWorkflow"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import EstimateService from "../services/EstimateService";
export default {
  props: ['userData'],
  data() {
    return {
      workflows: [],
      displayWorkflowDialog: false,
      newWorkflowName: null,
    };
  },
  EstimateService: null,
  created() {
    this.EstimateService = new EstimateService();
  },
  mounted() {
    let checkUserData = setInterval(() => {
      if (this.userData != null) {
        this.getAllWorkflows();
        clearInterval(checkUserData);
      }
    }, 200);
  },
  methods: {
    showNewWorkflowDialog() {
      this.newWorkflowName = null;
      this.displayWorkflowDialog = true;
    },
    createWorkflow() {
      const that = this;
      if (this.newWorkflowName) {
        const formData = new FormData();
        formData.append("action", "createWorkflow");
        formData.append("name", this.newWorkflowName);

        this.EstimateService.createWorkflow(formData).then((data) => {
          if (data.status) {
            that.$toast.add({
              severity: "success",
              detail: data.message,
              life: 3000,
            });
            that.displayWorkflowDialog = false;
            setTimeout(function () {
              that.$router.push("/workflows/" + data.id);
            }, 1000);
          } else {
            that.$toast.add({
              severity: "error",
              detail: data.message,
              life: 3000,
            });
          }
        });
      } else {
        this.$toast.add({
          severity: "warn",
          detail: "Необходимо заполнить все поля",
          life: 3000,
        });
      }
    },
    getAllWorkflows() {
      // получение всех разделов из БД
      this.EstimateService.getAllWorkflows().then((data) => {
        if (data.status) {
          this.workflows = data.message;
        } else {
          this.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    },
    confirmDeleteWorkflow(event, workflow_id) {
      // запрос подтверждения удаления сметы
      const that = this;
      this.$confirm.require({
        target: event.currentTarget,
        header: "Подтверждение",
        message: "Удалить этот раздел?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          that.removeWorkflow(workflow_id);
        },
      });
    },
    removeWorkflow(id) {
      // удаление раздела из БД
      const that = this;
      this.EstimateService.removeWorkflow(id).then((data) => {
        if (data.status) {
          that.$toast.add({
            severity: "success",
            detail: data.message,
            life: 3000,
          });
          that.getAllWorkflows();
        } else {
          that.$toast.add({
            severity: "error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    },
  },
};
</script>