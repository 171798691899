<template>
  <!-- <page-header :crumbsDisabled="true" /> -->
  <!-- <page-title>База материалов</page-title> -->
  <div class="container pt-5 px-3 md:px-3 lg:px-0">
    <!-- <div class="surface-ground px-4 py-5 md:px-6 lg:px-8"> -->
      <div class="grid px-3">
        <div class="col-12 md:col-6 lg:col-3">
          <div class="surface-card shadow-2 p-3 border-round">
            <div class="flex justify-content-between mb-3">
              <div>
                <span class="block text-500 font-medium mb-3">Всего смет</span>
                <div class="text-900 font-medium text-4xl">{{allEstimates.length}}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-blue-100 border-round"
                style="width:2.5rem;height:2.5rem"
              >
                <i class="pi pi-table text-blue-500 text-xl"></i>
              </div>
            </div>
            <!-- <span class="text-green-500 font-medium">24 new</span> -->
            <span class="text-500"><router-link to="/estimates">Перейти к списку</router-link></span>
          </div>
        </div>
        <div class="col-12 md:col-6 lg:col-3">
          <div class="surface-card shadow-2 p-3 border-round">
            <div class="flex justify-content-between mb-3">
              <div>
                <span class="block text-500 font-medium mb-3">Материалов в базе</span>
                <div class="text-900 font-medium text-4xl">{{allMaterials.length}}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-orange-100 border-round"
                style="width:2.5rem;height:2.5rem"
              >
                <i class="pi pi-inbox text-orange-500 text-xl"></i>
              </div>
            </div>
            <!-- <span class="text-green-500 font-medium">%52+</span> -->
            <span class="text-500"><router-link to="/objects">Перейти к списку</router-link></span>
          </div>
        </div>
        <div class="col-12 md:col-6 lg:col-3">
          <div class="surface-card shadow-2 p-3 border-round">
            <div class="flex justify-content-between mb-3">
              <div>
                <span class="block text-500 font-medium mb-3">Шаблонов</span>
                <div class="text-900 font-medium text-4xl">{{allTemplates.length}}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                style="width:2.5rem;height:2.5rem"
              >
                <i class="pi pi-th-large text-cyan-500 text-xl"></i>
              </div>
            </div>
            <!-- <span class="text-green-500 font-medium">520</span> -->
            <span class="text-500"><router-link to="/templates">Перейти к списку</router-link></span>
          </div>
        </div>
        <div class="col-12 md:col-6 lg:col-3">
          <div class="surface-card shadow-2 p-3 border-round">
            <div class="flex justify-content-between mb-3">
              <div>
                <span class="block text-500 font-medium mb-3">Пользователей</span>
                <div class="text-900 font-medium text-4xl">{{allUsers.length}}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-purple-100 border-round"
                style="width:2.5rem;height:2.5rem"
              >
                <i class="pi pi-users text-purple-500 text-xl"></i>
              </div>
            </div>
            <!-- <span class="text-green-500 font-medium">85</span> -->
            <span class="text-500"><router-link to="/users">Перейти к списку</router-link></span>
          </div>
        </div>
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import EstimateService from "../services/EstimateService";
import UserService from "../services/UserService";
export default {
    props: ['userData'],
    data() {
        return {
            // userData: {
            //     id: 1,
            //     group: "admin",
            //     name: "Александр Солдатов"
            // },
            allEstimates: [],
            allMaterials: [],
            allTemplates: [],
            allUsers: [],
        }
    },
    EstimateService: null,
    UserService: null,
    created() {
        this.EstimateService = new EstimateService();
        this.UserService = new UserService();
    },
    mounted() {
      let checkUserData = setInterval(() => {
        if (this.userData != null) {
          this.getAllEstimates(this.userData.permissions, this.userData.id);
          this.getAllMaterials();
          this.getAllTemplates();
          this.getAllUsers();
          clearInterval(checkUserData);
        }
      }, 200);
    },
    methods: {
        getAllEstimates(permissions, user_id) {
            const that = this;
            this.EstimateService.getAllEstimates(permissions, user_id).then(
                (data) => {
                if (data.status) {
                    that.allEstimates = data.message;
                } else {
                    that.$toast.add({
                    severity: "error",
                    detail: data.message,
                    life: 3000,
                    });
                }
                }
            );
        },
        getAllMaterials() {
            // получение всех материалов из БД
            this.EstimateService.getAllObjects().then((data) => {
                if (data.status) {
                this.allMaterials = data.message;
                }
            });
        },
        getAllTemplates() {
        // получение всех шаблонов из БД
            this.EstimateService.getAllTypes().then((data) => {
                if (data.status) {
                this.allTemplates = data.message;
                } else {
                this.$toast.add({
                    severity: "error",
                    detail: data.message,
                    life: 3000,
                });
                }
            });
        },
        getAllUsers() { // получение все пользователей из БД
            this.UserService.getAllUsers().then(data => {
                if (data.status) {
                this.allUsers = data.message;
                }
            })
        },
    }
}
</script>